import React, { useEffect, useState, useRef, useContext } from 'react'

import CustomDropdown from './CustomDropdown2';
import useRead from '../../src/domain/services/hookRead';
import { ReadSearch } from '../domain/services/readSearch';

const SingleSelectDbCity = ({ field, formik, loading, options, optionSelected, listener, required, onBlurHandler, labelField, placeholder, label, ref }) => {
    const [listcity, isLoadingcity, isErrorcity] = useRead({
        table: "tb_city",
        src: 'city',
        where: [["city_id", "!=", "0"]],
        orderBy: "city_name asc",
        limit: "50"
    }, ReadSearch);
    return (
        <CustomDropdown
            field={field}
            label={label}
            options={[listcity, 'tb_city', 'city_name', 'city_id']}
            labelField="name"
            loading={isLoadingcity}
            //listener={onListener}
            optionSelected='city_name'
            required={true}
            //onBlurHandler={()=> loadConfigCertificate()}
            placeholder="Cidade de realização do curso"
            formik={formik}
        />

    );
}

export default SingleSelectDbCity;