
import { ArrayToQParams } from '../../application/utils/arrayToQParams';
import instance from '../../infra/db/instance';
//import { AbstractSearch } from './abstractSearch';
import { FormatterValidator } from '../../infra/adapters/formatterValidator'
import SubscriberModel from './model';

export default class useServiceSearch extends SubscriberModel {
    constructor() {
        super();
    }
    async execute(args) {


        //console.log("subscriberModel.payload", args);
        const response = await instance({
            url: `/subscriber${ArrayToQParams(args)}&method=search&search=true`,
            method: "GET"
        }).catch(r => {
            //console.log("DEU ERRO: ", r);
            if (r.response.status == 403)
                throw ({
                    result: "",
                    resume: "Erro personalizado",
                    status: 403
                });
            else
                throw ({
                    result: "",
                    status: r.response.status,
                    resume: "deu erro no else"
                });


        })

        //console.log("~ RESPOSTA API ~", response);
        return response;
    }
    formatting(args) {
        let res = FormatterValidator.formatting(args, this.initialValues)
        this.payload = res;
        return res
    }

    async searching() {
        //console.log("FAZENDO A BUSCA USANDO SERACHING... ")
        //console.log(this.payload)
        //console.log(this.initialValues);
        let where = [];

        // Object.entries(this.payload[0]).map((item, value) => {

        // });
        const d = this.payload[0];


        if (d.subscriber_id)
            where.push(["A.subscriber_id", "IN", d.subscriber_id.map(item => item.code)]);

        if (d.subscriber_name)
            where.push(["A.subscriber_name", "LIKE", `${d.subscriber_name}%`]);

        if (d.dt_birth_start && d.dt_birth_end)
            where.push(["A.dt_birth", "BETWEEN", [d.dt_birth_start, d.dt_birth_end]]);

        if (d.cpf)
            where.push(["A.cpf", "=", d.cpf]);

        if (d.email)
            where.push(["A.email", "LIKE", `${d.email}%`]);

        if (d.whatsapp_number)
            where.push(["A.whatsapp_number", "=", d.whatsapp_number]);

        if (d._created_user_id)
            where.push(["A._created_user_id", "IN", d._created_user_id.map(item => item.code)]);

        if (d._updated_user_id)
            where.push(["A._updated_user_id", "IN", d._updated_user_id.map(item => item.code)]);

        if (d._created_at_start && d._created_at_end)
            where.push(["A._created_at", "BETWEEN", [d._created_at_start, d._created_at_end]]);

        if (d._updated_at_start && d._updated_at_end)
            where.push(["A._updated_at", "BETWEEN", [d._updated_at_start, d._updated_at_end]]);

        if (d.subscriber_status_id)
            where.push(["A.subscriber_status_id", "IN", d.subscriber_status_id.map(item => item.code)]);

        if (d.seller_id)
            where.push(["A.seller_id", "IN", d.seller_id.map(item => item.code)]);

        if (d.manager_id)
            where.push(["A.manager_id", "IN", d.manager_id.map(item => item.code)]);

        if (d._updated_at_sab_start && d._updated_at_sab_end)
            where.push(["A._updated_at_sab", "BETWEEN", [d._updated_at_sab_start, d._updated_at_sab_end]]);

        if (d.class_id)
            where.push(["A.class_id", "IN", d.class_id.map(item => item.code)]);

        if (d._responsible_user_id)
            where.push(["A._responsible_user_id", "IN", d._responsible_user_id.map(item => item.code)]);

        if (d.client_id)
            where.push(["A.client_id", "IN", d.client_id.map(item => item.code)]);

        if (d._updated_at_dom_start && d._updated_at_dom_end)
            where.push(["A._updated_at_dom", "BETWEEN", [d._updated_at_dom_start, d._updated_at_dom_end]]);


        //console.log("where: ", where);
        return {
            leftJoin: true,
            where: where,
            orderBy: "A.subscriber_id DESC",
            countAllRows: true,
            page: 1,
            limit: 10
        }
    }

}
