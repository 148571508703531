
import React, { useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

export default function ShowToast({ resume }) {
    const toastBottomRight = useRef(null);
    const showMessage = (ref) => {
        ref.current.show({ severity: resume.severity, summary: resume.summary, detail: resume.detail, life: 3000 });
    };
    useEffect(() => {
        console.log("useEffect errorSubmit :: resume :: Toast", resume);
        if (resume != null)
            showMessage(toastBottomRight)
    }, [resume])
    return (
        <>
        

                <Toast ref={toastBottomRight} position="bottom-right" />
            {/* <div className="card flex justify-content-right">
            </div> */}
        
        </>
    )
}
