import React, { memo, useState } from 'react';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';
import { FormatterValidator } from '../infra/adapters/formatterValidator';

const CustomInputMaskSEARCH = ({ field, label, args, onBlur, readonly, placeholder,formik, onKeyUp, required, autoComplete,dataSet,onChange }) => {

    const [getMask, setMask] = useState(args.mask);

  return (
    <>
      <label htmlFor={field}>{label}</label>
      <InputMask
        id={field}      
        
        //mask={dataSet[field].replace(/\D/g, '').length <= 7 ? '999.999.999-99' : '99.999.999/9999-99'}
        readOnly={readonly || args?.action === "view" ? true : false}        
        autoComplete={autoComplete ?? "on"}
        mask={getMask}        
        value={dataSet[field]}
        className={`form-control pl-1`}
        name={field}
        placeholder={placeholder}
        style={{textTransform:'uppercase'}}
        onChange={(e) => onChange(e)}
      />
      
    </>
  );
};

export default memo(CustomInputMaskSEARCH);
