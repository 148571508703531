import React, { useEffect, useState, useRef, useContext } from 'react'
import { useLocation } from 'react-router'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { loadFromLocalStorage } from '../../utils/localStorageUser'
import { useNavigate } from 'react-router-dom'
import { useApi } from "../../hooks/useApi";


/* UTILITÁRIOS */
import Permission from '../../utils/Permission.js'

/* COMPONENTES */
import { CustonSwal } from '../../components/Swal';


        function Change() {
            const { provider_user, provider_session, provider_association } = useContext(AuthContext)
            const auth = useContext(AuthContext)
            

        const location = useLocation()        


        const navigate = useNavigate();        
        useEffect(() => {      
            console.clear();
            let client_id=location.pathname.split("/").pop();
            //console.log(":::::::::::::::::::::::::::::::::::::",provider_association.filter(el=>el.client_id==client_id));      
            auth.changeClient(client_id)
            .then(r=>{
                console.log(">>>>>>>>>>>>>>>>>>>>>>>>",r);
                navigate('/admin/register/class')
            })
            //auth.changeClient(provider_association.filter(el=>el.client_id==client_id)[0])
            
        }, [])
        return (
        <>
        <div className="page-wrapper">

<div className="page-inner">

    <div className="page-content-wrapper">
        <main id="js-page-content" role="main" className="page-content">
                    <h3 style={{display: 'flex',  alignItems: 'center', justifyContent: 'center', height: '80vh'}}>
                        Alternando região...
                    </h3>
        </main>        
    </div>
</div >

</div >
        </>
    )
    }

        export default Change;