import { ArrayToQParams } from '../../application/utils/arrayToQParams';
import instance from '../../infra/db/instance';
//import { AbstractSearch } from './abstractSearch';
import { FormatterValidator } from '../../infra/adapters/formatterValidator'
import PearsonModel from './model';

export default class useServiceSearch extends PearsonModel {
    constructor() {
        super();
    }
    async execute(args) {

        //console.log("pearsonModel.payload", args);
        const response = await instance({
            url: `/pearson${ArrayToQParams(args)}&method=search&search=true`,
            method: "GET"
        }).catch(r => {
            //console.log("DEU ERRO: ", r);
            if (r.response.status == 403)
                throw ({
                    status: 403,
                    result: "",
                    resume: "Erro personalizado"
                });
            else
                throw ({
                    result: "",
                    status: r.response.status,
                    resume: "deu erro no else"
                });

        })

        //console.log("~ RESPOSTA API ~", response);
        return response;
    }
    formatting(args) {
        let res = FormatterValidator.formatting(args, this.initialValues)
        this.payload = res;
        return res
    }

    async searching() {
        //console.log("FAZENDO A BUSCA USANDO SERACHING... ")
        //console.log(this.initialValues);
        let where = [];

        // Object.entries(this.payload[0]).map((item, value) => {

        // });
        const d = this.payload[0];


        if (d.dt_was_born_start && d.dt_was_born_end)
            where.push(["A.dt_was_born", "BETWEEN", [d.dt_was_born_start, d.dt_was_born_end]]);

        if (d.pearson_name)
            where.push(["A.pearson_name", "LIKE", `${d.pearson_name}%`]);

        if (d.nickname)
            where.push(["A.nickname", "LIKE", `${d.nickname}%`]);

        if (d.document_additional)
            where.push(["A.document_additional", "LIKE", `000${d.document_additional}%`]);

        if (d.document)
            where.push(["A.document", "=", `${d.document}`]);

        if (d.email)
            where.push(["A.email", "LIKE", `${d.email}%`]);

        if (d.gender_id)
            where.push(["A.gender_id", "IN", d.gender_id.map(item => item.code)]);

        //console.log("where: ", where);
        return {
            leftJoin: true,
            where: where,
            orderBy: "A.pearson_id DESC",
            countAllRows: true,
            page: 1,
            limit: 10
        }
    }

}