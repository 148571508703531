
        import instance from '../../infra/db/instance';
        import BranchModel  from './model';
        import { FormatterValidator } from '../../infra/adapters/formatterValidator'
       
        export default class useServicePut extends BranchModel {
        constructor() {
            super();
        }
        initValues() {
            return true
        }
       
        validation(args) {
            let res = FormatterValidator.formatAndValidate(args, this.initialValues)
            return res
        }
       
        formatting(args) {
            let res = FormatterValidator.formatting(args, this.initialValues)
            this.payload = res;
            return res
        }
       
        masking(args) {
            let res = FormatterValidator.masking(args, this.initialValues)
            this.payload = res;
            return res
        }
       
        async execute(action,args) {
            let config = { post: ["create", "post"], patch: ["patch", "PATCH"] }
            //console.log("CLASS CREATE CONFIG: ", config[action], args);
            const response = await instance({
            url: `/branch?method=${config[action][0]}`,
            method: `${config[action][1]}`,
            data: args
            }).catch(r=>{
               return r
            })
            return response
        }
       
        }
        