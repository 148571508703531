import React, { memo } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';

const CustomInputText = ({ field, label, args, readonly, formik, required, className,placeholder }) => {
  const handleFieldOnBlurDefault = (e) => {
    console.log("eeeeeeeeeeeeeeee3",field,e.target.value);
    formik.errors[field]="Inválido";
  }
  const handleFieldChange = (e) => {

    const inputValue = args?.onlyNumbers ? e.target.value.replace(/\D/g, '') : e.target.value;
    formik.setFieldValue(field, inputValue);

  };
  
  // faz a verificação pra saber se o componente sera obrigatorio ou não
  //let isRequired = required ? classNames({ 'is-invalid': formik.touched[field] && formik.errors[field] }) : "";
  let isRequired = required || formik.errors[field] ? classNames({ 'is-invalid': formik.touched[field] && formik.errors[field] }) : "";

  return (
    <>
      <label htmlFor={field}>{label}</label>
      <InputText
        id={field}
        value={formik.values[field]}
        className={`form-control ${isRequired} pl-1`}
        name={field}
        placeholder={placeholder}
        style={{textTransform:'uppercase'}}
        readOnly={readonly || args?.action === "view" ? true : false}
        onBlur={formik.handleBlur}
        onChange={(e) => handleFieldChange(e)}
        prefix="+055"
      />
      {formik.touched[field] && formik.errors[field] && <div className='invalid-feedback'>{formik.errors[field]}</div>}
    </>
  );
};

export default memo(CustomInputText);
