export default class Model {
    constructor() {
        this.error = [];

        if (new.target === Model) {
            throw new Error('Não é possível instanciar uma classe abstrata diretamente.');
        }

        this.initialValues = {
            "pearson_id": {
                onCreate: "0",
                type: "int"
            },
            "pearson_name": {
                onCreate: "1",
                formatter: ["formatterTrim", "formatterUpperCase"],
                type: "varchar"
            },
            "nickname": {
                formatter: ["formatterTrim", "formatterUpperCase"],
                type: "varchar"
            },
            "document": {
                onCreate: "1",
                formatter: ["formatterNumber"],
                mask: "cpf",
                type: "bigint"
            },
            "document_additional": {
                formatter: ["formatterTrim"],
                type: "varchar"
            },
            "gender_id": {
                onCreate: "1",
                type: "tinyint"
            },
            "dt_was_born": {
                onCreate: "1",
                formatter: ["formatterDate"],
                mask: "dmY",
                type: "date"
            },
            "email": {
                onCreate: "1",
                formatter: ["formatterTrim"],
                type: "varchar"
            },
            "cell_phone": {
                onCreate: "1",
                formatter: ["formatterNumber", "formatterPhone"],
                mask: "cell_phone",
                type: "bigint"
            },
            "contact": {
                type: "varchar"
            },
            "nationality_id": {
                onCreate: "1",
                type: "tinyint"
            },
            "_created_user_id": {
                onCreate: "0",
                formatter: ["formatterInteger"],
                type: "mediumint"
            },
            "_updated_user_id": {
                onCreate: "0",
                formatter: ["formatterInteger"],
                type: "mediumint"
            },
            "_created_at": {
                onCreate: "0",
                formatter: ["formatterDate"],
                mask: "dmY",
                type: "datetime"
            },
            "_updated_at": {
                onCreate: "0",
                formatter: ["formatterDate"],
                mask: "dmY",
                type: "datetime"
            },
            "pearson_status_id": {
                onCreate: "0",
                type: "mediumint"
            }
        };
    }
    validate() {
        throw new Error('Método "validate" deve ser implementado pelas subclasses.');
    }
    create() {
        throw new Error('Método "create" deve ser implementado pelas subclasses.');
    }
    execute() {
        throw new Error('Método "execute" deve ser implementado pelas subclasses.');
    }
}