import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

/* COMPONENTES */
import MyButton from '../../components/MyButton'

/* AUXILIARES */
import Alert from '../../components/Alert.jsx';
import { AuthContext } from '../../Contexts/Auth/AuthContext.js';
import Permission from '../../utils/Permission.js';
import UCCreateBranch from './useCaseCreate.js';
import CustomInput from '../../components/CustomInput.jsx';
import ShowToast from '../../components/Toast.jsx';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'

/* select personalizados */
import SelectSingleDbStatus from '../../components/selectSingleDbStatus.jsx';


const FragmentForm = forwardRef((props, ref) => {
    const modalRef = useRef();
    const buttonRef = useRef(null);

    const openModal = () => {
        if (modalRef.current) {
            const modal = new window.bootstrap.Modal(modalRef.current);
            modal.show();
        }
    };

    const closeModal = () => {
        buttonRef.current.click(); // Simula o clique no botão
    };
    const {
        formik,
        onResume,
        isSubmiting,
        editData,
        action,
        handleClickCancel
    } = UCCreateBranch();

    useEffect(() => {
        console.log("useEffect onResume", onResume);
        if (onResume != null)
            closeModal()
    }, [onResume])

    useEffect(() => {
        console.log("useEffect onResume", onResume);
        if (editData != null)
            openModal();
    }, [editData])

    return (
        <>
            {
                isSubmiting &&
                <CircleSpinnerOverlay
                    loading={true}
                    zIndex="9999"
                    message="Carregando..."
                    overlayColor="rgba(0,153,255,0.5)"
                />
            }
            {
                action == "patch" && editData == null ?
                    <Alert text={<><strong>Sinto muito!!</strong> Não foi enviado uma informação para edição</>} icon="" className="danger" iconAlign="left" />
                    : (
                        editData != null &&
                        <>

                        </>
                    )
            }
            <div className="modal fade" id="exampleModalgrid" ref={modalRef} tabindex="-1" aria-labelledby="exampleModalgridLabel">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalgridLabel">
                                {
                                    action == "patch" ? "Edição" : "Criação"
                                }
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={buttonRef} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={formik.handleSubmit} className="validate m-t needs-validation" noValidate id="form-create-update">
                                <div className="panel-content">
                                    <div className="form-row">
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-text" field="branch_name" id="validationCustom01" required placeholder="Nome da filial" label='Nome da filial' formik={formik} args={{ action: action }} />
                                        </div>
                                        {
                                            action == "patch" && <div className="col-md-auto mb-3">
                                                <SelectSingleDbStatus field="branch_status_id" label="Situação" formik={formik} />
                                            </div>

                                        }<div className="col-md-auto mb-3">
                                            <CustomInput type="input-mask" field="cell_phone" id="validationCustom01" placeholder="Telefone celular" label='Telefone celular' formik={formik} args={{ action: action, mask: "(99) 9 9999-9999" }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-mask" field="phone" id="validationCustom01" placeholder="Telefone fixo" label='Telefone fixo' formik={formik} args={{ action: action, mask: "(99) 9 9999-9999" }} />
                                        </div>
                                        <div className="col-md-auto mb-3">
                                            <CustomInput type="input-text" field="email" id="validationCustom01" required placeholder="Email" label='Email' formik={formik} args={{ action: action }} />
                                        </div>

                                    </div>
                                </div>
                                <div className="panel-content border-faded border-left-0 modal-footer border-right-0 border-bottom-0 d-flex flex-row   bg-faded">
                                    {
                                        action == "patch" &&
                                        <MyButton
                                            name="btnCancel"
                                            text="Cancelar"
                                            type="button"
                                            onClick={(el) => handleClickCancel("btnCancel")}
                                            icon="fal fa-times"
                                            btnClass="btn btn-default ml-auto" />
                                    }
                                    {
                                        action == "patch" &&
                                        <MyButton
                                            name="btnPatch"
                                            text="Novo"
                                            type="button"
                                            onClick={(el) => handleClickCancel("btnNew")}
                                            icon="fal fa-plus"
                                            btnClass="btn btn-success ml-2" />
                                    }
                                    <MyButton
                                        name="btnSave"
                                        text="Salvar"
                                        type="submit"
                                        loading={isSubmiting}
                                        icon="fal fa-save"
                                        btnClass="btn btn-primary ml-2" />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ShowToast resume={onResume}></ShowToast>
        </>
    );
})

export default FragmentForm;