export class FormatterValidator {
    static formatAndValidate(data, schema) {
        const formattedData = {}
        //console.log("data",data);

        Object.keys(schema).forEach(prop => {

            data.forEach(element => {

                if (element.hasOwnProperty(prop)) {

                    if (schema[prop].onCreate == true && (element[prop] == "" || element[prop] == null)) {
                        formattedData[prop] = "Campo obrigatório";

                    } else {
                        if (schema[prop].formatter) {
                            schema[prop].formatter.forEach((format) => {
                                let $this = this;
                                let result = element[prop];
                                console.log("xxxxxxxxxxxxx result",result);
                                /* if (typeof $this[format] === "function") {
                                    result = $this[format](element[prop]);
                                } */
                                if (schema[prop].validator) {
                                    schema[prop].validator.forEach((valid) => {
                                        try {
                                            const result2 = $this[valid](result);
                                        } catch (error) {
                                            //console.log("error.description: ", error.description);
                                            formattedData[prop] = error.description
                                        }
                                    })
                                }

                            });
                        }
                    }
                }

                //console.log(`Elemento NÃO tem a propriedade ${prop} do schema. O onCreate dela é ${schema[prop].onCreate}`);


            });

        });

        /* const formattedData = {
            subscriber_name: data.subscriber_name,
            whatsapp_number: this.formatNumbers(data.whatsapp_number),
            class_id: data.class_id,
            seller_id: data.seller_id,
            email: this.formatEmail(data.email),
            manager_id: data.manager_id,
            dt_birth: this.formatDate(data.dt_birth),
            cpf: this.formatCPF(data.cpf),
            manager_name: this.formatName(data.manager_name),
            seller_name: data.seller_name,
            course_name: data.course_name
        }; */

        return formattedData;
    }
    static formatting(data, schema) {
        const newData = []
        const newProps = {}
        let valueProperty;
        data.forEach((row, i) => {
            Object.keys(row).forEach(col => {
                const columnNameSchema = col;//nome_completo_start
                let columnNameCortado = col;
                const split = col.split("_");
                const lastItem = split[split.length - 1];
                if (["_start", "_end"].includes(`_${lastItem}`))
                    columnNameCortado = columnNameSchema.replace(`_${lastItem}`, "")//nome_cortado
                valueProperty = row[columnNameSchema];

                console.log("columnNameCortado|valueProperty",columnNameCortado+"|"+valueProperty); 

                if (schema.hasOwnProperty(columnNameCortado)) {

                    let create = schema.hasOwnProperty('onCreate');
                    let update = schema.hasOwnProperty('onUpdate');
                    //console.log(" ::::::: col ::::::::",col,schema[col].onCreate,schema[col].onUpdate);

                    if (schema[columnNameCortado].formatter && (valueProperty !=null && valueProperty !=undefined && valueProperty !="")) {
                        //console.log("col", col);
                        schema[columnNameCortado].formatter.forEach((format) => {
                            console.log("::::::::::::::::::::::: format " + col, format, valueProperty);
                            let $this = this;
                            if (typeof $this[format] === "function") {
                                //col = $this[format](valueProperty);
                                valueProperty = $this[format](valueProperty);
                                //console.log("format", format, col);
                            }
                        });
                    }

                    newProps[columnNameSchema] = valueProperty
                } else {
                    newProps[columnNameSchema] = valueProperty
                }
            })

            //newData.push({...row, [col]:result})
            newData.push(newProps)


        });

        /* Object.keys(schema).forEach(prop => {
           
            

        }); */

        /* const formattedData = {
            subscriber_name: data.subscriber_name,
            whatsapp_number: this.formatNumbers(data.whatsapp_number),
            class_id: data.class_id,
            seller_id: data.seller_id,
            email: this.formatEmail(data.email),
            manager_id: data.manager_id,
            dt_birth: this.formatDate(data.dt_birth),
            cpf: this.formatCPF(data.cpf),
            manager_name: this.formatName(data.manager_name),
            seller_name: data.seller_name,
            course_name: data.course_name
        }; */

        return newData;
    }
    static masking(data, schema) {
        console.log("FORMATING MASKING RECEBEU", data, schema);
        let newData = []
        let result;
        return data.map((row, i) => {
            let newProps = {}
            Object.keys(row).forEach(col => {
                result = row[col];
                if (result == null)
                    result = "";

                if (schema.hasOwnProperty(col)) {
                    if (schema[col].mask != undefined) {
                        //console.log("schema[" + col + "].mask", schema[col].mask);
                        let $this = this;
                        result = $this[schema[col].mask](result);
                    }
                    newProps[col] = result
                } else {
                    newProps[col] = result
                }
            })
            //console.log("RESULT newProps", newProps);
            //newData.push(newProps)
            return newProps;
        });
        /* //console.log("newDatanewDatanewDatanewData",newData);
                return newData; */
    }
    static cpf(value, key = "M", args = {}) {
        if (value) {
            value = value.replace(/\D/g, '');

            if (value) {

                if (key === "J")
                    return value.toString().replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                else if (key === "M" || key === "F") {
                    if (value.length == 14)
                        return value.toString().substring(3).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                    else if (value.length == 11)
                        return value.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                    else if (value.length > 14)
                        return "ERRO"
                    else
                        return "erro"
                }
                else {

                }
            }
        }
    }


    static cell_phone(value, key, args = {}) {
        /* MODELO USADO POR MAZULLO */
        if (value) {
            let newValue = `${value}`;            
                newValue = newValue.replace(/^0+/, '').slice(0, 11);
            return newValue.toString().replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '+$1 ($2) $3 $4-$5')
        }
        
        /* 
        MODELO QUE DEVERÁ SER ADOTADO 
        if (value) {
            let newValue = `${value}`;
            if (value.startsWith('055'))
                newValue = newValue.replace(/^0+/, '').slice(0, -1);
            return newValue.toString().replace(/(\d{3})(\d{2})(\d{1})(\d{4})(\d{4})/, '+$1 ($2) $3 $4-$5')
        } */

    }

    /* 
    static masking(data, schema) {
        //console.log("MASKING RECEBEU", data);
        const newData = [];
    
        data.forEach((row) => {
            const newProps = {}; // Criar um novo objeto para cada linha de dados
    
            Object.keys(row).forEach(col => {
                let result = row[col];
                if (result == null) {
                    result = "";
                }
    
                if (schema.hasOwnProperty(col) && schema[col].mask) {
                    //console.log("Tem mask", col);
                    const maskFunction = schema[col].mask;
    
                    if (typeof maskFunction === "function") {
                        result = maskFunction(result);
                        //console.log("result", result);
                    }
                }
    
                newProps[col] = result;
            });
    
            newData.push(newProps); // Adicionar o objeto atual ao array newData
        });
    
        return newData;
    } */
/* 
ESSA FUNÇÃO TRANSFORMA UMA STRING, REMOVE TODOS OS CARACTERES QUE NÃO SEJA NÚMEROS
*/
    static formatterNumber(str) {
        console.log("formatterNumber", str);
        // Remove caracteres não numéricos
        return str.replace(/\D/g, '');
    }
    
/* 
ESSA FUNÇÃO TRANSFORMA UMA STRING, REMOVE TODOS OS CARACTERES QUE NÃO SEJA NÚMEROS
*/
    static formatterPhone(str) {
        //console.log("formatterPhone", str);        
        // Remove caracteres não numéricos
        return str.replace(/\D/g, '');

    }

    static strToNumber(value) {
        //console.log("strToNumber", value);
        // Remove caracteres não numéricos
        if (value)
            return parseFloat(value);
    }

    static formatterMonetary(value) {
        //console.log("value formatMonetary: ", value, typeof value);
        // Remove caracteres não numéricos
        if (value)
            return this.strToNumber(value).toFixed(2);
    }

    /* static formatterFloat(value) {
        //console.log("value formatMonetary: ", value, typeof value);
        // Remove caracteres não numéricos
        if (value)
            return this.strToNumber(value).toFixed(2);
    } */

    static formatterString(value) {
        if (value)
            return value.toString();
    }

    static formatterEmail(email) {
        //console.log("formatEmail", email);
        // Remove espaços em branco e converte para minúsculas
        return email.replace(/\s/g, '').trim().toLowerCase();
    }

    static formatterDate(date) {
        //Input: dd/mm/aaaa
        //Output: YYYY-mm-dd
        console.log("formatDate", date);
        if (date) {
            const parts = date.split('/');
            const formattedDate = parts.reverse().join('-'); // Inverte a ordem para aaaa-mm-dd
            console.log("formattedDate", date);
            return formattedDate;
        }
    }
    static dateBrToUs(date) {
        // Formato esperado: dd/mm/aaaa
        const parts = date.split('/');
        const formattedDate = parts.reverse().join('-'); // Inverte a ordem para aaaa-mm-dd
        return formattedDate;
    }
    static dateUsToBr(date) {
        // Formato esperado: YYYY/mm/dd
        const parts = date.split('-');
        const formattedDate = parts.reverse().join('/'); // Inverte a ordem para aaaa-mm-dd
        return formattedDate;
    }
    static datetimeUsToBr(date) {

        const parts = date.split(' ');
        const partDate = parts[0];
        const partTime = parts[1];

        const splitDate = partDate.split('-');

        const formattedDate = splitDate.reverse().join('/') + " " + partTime; // Inverte a ordem para aaaa-mm-dd
        return formattedDate;
    }
    static BRL2(value) {
        if (value)
            return this.strToNumber(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    static BRL3(value) {
        if (value)
            return this.strToNumber(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 3 });
    }

    static formatterCPF(cpf) {
        // Remove caracteres não numéricos
        return cpf.replace(/\D/g, '');
    }

    static formatterName(name) {
        // Remove espaços em branco extras e capitaliza as palavras
        return name.trim().replace(/\s+/g, ' ').toUpperCase();
    }

    static formatterTrim(name) {
        // Remove espaços em branco extras e capitaliza as palavras
        return name.trim().replace(/\s+/g, ' ');
    }

    static dmY(value) {
        // Remove espaços em branco extras e capitaliza as palavras
        return this.dateUsToBr(value);
    }

    static dmYHis(value) {
        // Remove espaços em branco extras e capitaliza as palavras
        return this.datetimeUsToBr(value);
    }

    static formatterUpperCase(name) {
        // Remove espaços em branco extras e capitaliza as palavras
        return name.trim().replace(/\s+/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    }

    static validateCPF(cpf) {
        // Algoritmo para validar CPF
        cpf = this.formatCPF(cpf);
        if (!cpf || cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
            throw {
                "description": "CPF deve ter 11 números"
            }
        }

        let sum = 0;
        for (let i = 0; i < 9; i++) {
            sum += parseInt(cpf.charAt(i)) * (10 - i);
        }

        let checkDigit1 = 11 - (sum % 11);
        if (checkDigit1 === 10 || checkDigit1 === 11) {
            checkDigit1 = 0;
        }

        if (parseInt(cpf.charAt(9)) !== checkDigit1) {
            throw {
                "description": "Cpf inválido"
            }
        }

        sum = 0;
        for (let i = 0; i < 10; i++) {
            sum += parseInt(cpf.charAt(i)) * (11 - i);
        }

        let checkDigit2 = 11 - (sum % 11);
        if (checkDigit2 === 10 || checkDigit2 === 11) {
            checkDigit2 = 0;
        }

        if (parseInt(cpf.charAt(10)) !== checkDigit2) {
            throw {
                "description": "CPF não tem números validos"
            }
        }

        return true;
    }

    static validateEmail(email) {
        if (email) {
            const emailRegex = /^[A-Za-z0-9_@+.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
            let res = emailRegex.test(email);

            if (email.indexOf(" ") >= 0)
                throw {
                    "description": "E-mail não pode ter espaços em branco."
                }
            if (!res)
                throw {
                    "description": "E-mail não é valido"
                }
        }
    }

    static validateDate(date) {//recebe o padrão BR: dd/mm/yyyy
        if (date) {
            /* Se o padrão enviado for americano, então inverte para o BR */
            if (date.indexOf("-"))
                date = this.dateUsToBr(date);
            // Algoritmo para validar data no formato aaaa-mm-dd
            const dateRegex = /^(?:(?:(?:0[1-9]|1\d|2[0-8])\/(?:0[1-9]|1[0-2]))|(?:29\/(?:0[13-9]|1[0-2]))|(?:(?:30|31)\/(?:0[13578]|1[02]))|(?:(?:29|30)\/(?:0[1,3-9]|1[0-2])))\/(?:19|20)\d{2}$|(?:29\/02\/(?:(?:19|20)(?:04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)))$/
            let res = dateRegex.test(date);
            //console.log("res", res);
            if (!res)
                throw {
                    "description": "Data inválida"
                }

        }
        return true
    }
}

// Dados de exemplo
/* const data = {
    "subscriber_name": "DQEFQEFQFQF",
    "whatsapp_number": "(86) 9 9811-1234",
    "class_id": "2402001",
    "seller_id": "400",
    "email": " NEGR EIROS @ icpoficial .COM. br ",
    "manager_id": "11",
    "dt_birth": "28/06/1986",
    "cpf": "026.936.033-66",
    "manager_name": " marcelo negreiros ",
    "seller_name": "Negreiros",
    "course_name": "VIDA PRÓSPERA"
}; */

// Formatar e validar os dados
/* const formattedData = FormatterValidator.formatAndValidate(data);
//console.log(formattedData); */

//console.log('CPF válido:', FormatterValidator.validateCPF(formattedData.cpf));
//console.log('Email válido:', FormatterValidator.validateEmail(formattedData.email));
//console.log('Data válida:', FormatterValidator.validateDate(formattedData.dt_birth));
