import { ReactComponent as Loader } from '../assets/icons/LoadButton.svg'

const MyButton = ({ name,activeButton,onClick,btnClass, text, loading = false, disabled,icon,type='button',messageLoading=''}) => {  
  const showLoading = (name === activeButton && loading === true) ?? false;
  return (
    <>
    <button type={type} className={btnClass} onClick={onClick} disabled={disabled}>
      {!showLoading &&(<i className={icon} />)} {!showLoading ? text : <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {messageLoading}</>}
    </button>
    {/* <button className="btn btn-info waves-effect waves-themed" type="button" disabled="">
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button> */}
    </>
  )
}


export default MyButton