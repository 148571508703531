
import React, { useEffect, useState, useContext } from 'react'

class QueryBuilder {
    constructor() {
        this.query = {
            leftJoin: false,
            where: [],
            orderBy: "",
            countAllRows: false,
            page: 1,
            limit: 10
        };
    }

    leftJoin(value) {
        this.query.leftJoin = value;
        return this;
    }

    where(condition) {
        this.query.where.push(condition);
        return this;
    }

    orderBy(orderBy) {
        this.query.orderBy = orderBy;
        return this;
    }

    countAllRows(value) {
        this.query.countAllRows = value;
        return this;
    }

    page(page) {
        this.query.page = page;
        return this;
    }

    limit(limit) {
        if (limit == "ALL")
            delete this.query.limit;
        else
            this.query.limit = limit;
        return this;
    }

    build() {
        console.log("this.query",this.query);
        return this.query;
    }
}

export default QueryBuilder;
//export default dataTable;
