import React, { useEffect, useState, useRef, useContext } from 'react'

        import CustomDropdown from './CustomDropdown2';
        import useRead from '../../src/domain/services/hookRead';
        import { ReadSearch } from '../domain/services/readSearch';        

        const SingleSelectDbClassModality = ({ field, formik,loading, options,optionSelected, listener,required, onBlurHandler, labelField, placeholder, label,ref }) => {      
                const [listclass_modality, isLoadingclass_modality, isErrorclass_modality] = useRead({
                    table: "tb_class_modality",
                    src: 'class_modality',
                    where: [["class_modality_id", "!=", "0"]],
                    orderBy: "class_modality_name asc",
                    limit: "50"
                },ReadSearch);
            return (
                <CustomDropdown
                    field={field}
                    label={label??"Modalidade"}
                    options={[listclass_modality, 'tb_class_modality', 'class_modality_name', 'class_modality_id']} 
                    labelField="name" 
                    loading={isLoadingclass_modality} 
                    //listener={onListener}
                    optionSelected='class_modality_name' 
                    required={true} 
                    //onBlurHandler={()=> loadConfigCertificate()}
                    placeholder="Modalidade da turma"
                    formik={formik}
                />

            );
        }

        export default SingleSelectDbClassModality;