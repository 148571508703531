import moment from 'moment';

const SingMask = {
    toMonetary: (value, key, args = {}) => {
        let options = {
            currency: "BRL",
            style: "currency",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }
        let format = "pt-br";
        let REAL = new Intl.NumberFormat(format, options);
        return REAL.format(value);
    },
    toDate: (value, key, args = {}) => {
        //console.log("~~~~~~~~~~ value", value, key);
        if (value) {
            let formatIsBr = value.indexOf("-") > -1 ? false : true;
            //console.log("formatIsBr", formatIsBr,value);//dmYHi

            if ((formatIsBr && (key === "dmYHi" || key === "dmYHis" || key === "dmY")) || (!formatIsBr && (key === "YmdHi" || key === "YmdHis" || key === "Ymd")))
                return value;

            let format = "";
            if (value === null || value === undefined)
                return;
            let split = value.split("-");
            if (value === "0000-00-00 00:00:00")
                return <span><i className="fa fa-exclamation-triangle text-danger" title="Data está incorreta"></i></span>;
            if (value === null)
                return "";
            if (key === "dmY") {
                format = 'DD/MM/YYYY';
            }

            if (split.length > 0 && split[0] > 1000)
                format = 'DD/MM/YYYY';

            if (key === "dmYHis")
                format = 'DD/MM/YYYY HH:mm:ss';

            if (key === "dmYHi")
                format = 'DD/MM/YYYY HH:mm';

            //console.log("Chegou ate aqui",format,value);
            return moment(value).format(format);
        }
    },
    /* FAZ AS CONVERSOES DE CPF E CNPJ BRASIL E PORTUGAL LEMBRANDO QUE É APENAS UMA MÁSCARA E NÃO FAZ CONVERSÃO*/
    toDocument: (value, key = "M", args = {}) => {
        if (value) {
            value = value.replace(/\D/g, '');

            if (value) {

                if (key === "J")
                    return value.toString().replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                else if (key === "M" || key === "F") {
                    if (value.length == 14)
                        return value.toString().substring(3).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                    else if (value.length == 11)
                        return value.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                    else if (value.length > 14)
                        return "ERRO"
                    else
                        return "erro"
                }
                else {

                }
            }
        }
    },
    validaCpf(cpf) {
        if (cpf) {
            cpf = cpf.replace(/\D/g, '');
            if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
            var result = true;
            [9, 10].forEach(function (j) {
                var soma = 0, r;
                cpf.split(/(?=)/).splice(0, j).forEach(function (e, i) {
                    soma += parseInt(e) * ((j + 2) - (i + 1));
                });
                r = soma % 11;
                r = (r < 2) ? 0 : 11 - r;
                if (r != cpf.substring(j, j + 1)) result = false;
            });
            return result;
        } else
            return "Doc. inválido";

    },
    toPhone: (value, key, args = {}) => {
        if (value) {
            let newValue = `${value}`;
            // if (value.startsWith('55')) 
                // padrão: newValue = newValue.slice(0, -1);
                newValue = newValue.slice(0, 11);

            
            //console.log("newValue",newValue);
            return newValue.toString().replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4')
            //return newValue.toString().replace(/(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/, '+$1 ($2) $3 $4-$5')
        }
    }
};

export default SingMask;
