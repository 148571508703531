
export default class Model {
    constructor() {
        this.error = [];

        if (new.target === Model) {
            throw new Error('Não é possível instanciar uma classe abstrata diretamente.');
        }

        this.initialValues = {

            "branch_id": {
                type: "tinyint"
            },
            "branch_name": {
                onCreate: "1",
                formatter: ["formatterTrim", "formatterUpperCase"],
                type: "varchar"
            },
            "_created_user_id": {
                type: "mediumint"
            },
            "_updated_user_id": {
                type: "mediumint"
            },
            "_created_at": {
                type: "datetime"
            },
            "_updated_at": {
                type: "datetime"
            },
            "branch_status_id": {
                onCreate: "0",
                type: "mediumint"
            },
            "logo_img": {
                type: "varchar"
            },
            "cell_phone": {
                onCreate: "1",
                formatter: ["formatterNumber", "formatterPhone"],
                mask: "cell_phone",
                //validator:["integer"],
                type: "bigint"
            },
            "phone": {
                onCreate: "1",
                formatter: ["formatterNumber", "formatterPhone"],
                mask: "cell_phone",
                type: "bigint"
            },
            "email": {
                onCreate: "1",
                formatter: ["formatterTrim"],
                type: "varchar"
            }
        };
    }
    validate() {
        throw new Error('Método "validate" deve ser implementado pelas subclasses.');
    }
    create() {
        throw new Error('Método "create" deve ser implementado pelas subclasses.');
    }
    execute() {
        throw new Error('Método "execute" deve ser implementado pelas subclasses.');
    }
}
