import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const Alert = ({ text, icon,className }) => {


    return (
        <>
            <div className={`alert alert-${className}`} role="alert">
                {text}
            </div>
        </>
    );
}

export default Alert;
