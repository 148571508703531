import axios from "axios";

class HttpClient {
    constructor(baseURL, timeout) {
        this.instance = axios.create({
            baseURL: baseURL,
            timeout: timeout
        });
    }

    setToken(token) {
        this.instance.defaults.headers.common = token;
    }

    async get(url, config) {
        try {
            const response = await this.instance.get(url, config);
            return response.data;
        } catch (error) {
            throw new Error(`Erro ao fazer requisição GET para ${url}: ${error.message}`);
        }
    }

    async post(url, data, config) {
        return new Promise((resolve, reject) => {
            this.instance.post(url, data, config)
                .then(response => {                    
                    if (response.data.status == 201)
                        resolve(response.data);
                    else
                        reject(response.data);
                    // Se a requisição for bem-sucedida, resolvemos a promessa com os dados da resposta
                })
                .catch(error => {
                    
                    // Se houver um erro na requisição, rejeitamos a promessa com o erro recebido
                    reject(error);
                });
        });


    }
    async patch(url, data, config) {
        return new Promise((resolve, reject) => {
            this.instance.patch(url, data, config)
                .then(response => {                    
                    if (response.data.status == 201)
                        resolve(response.data);
                    else
                        reject(response.data);
                    // Se a requisição for bem-sucedida, resolvemos a promessa com os dados da resposta
                })
                .catch(error => {
                    
                    // Se houver um erro na requisição, rejeitamos a promessa com o erro recebido
                    reject(error);
                });
        });


    }

    // Métodos para outros tipos de requisições (PUT, DELETE, etc.) podem ser adicionados conforme necessário
}

export default HttpClient;