   
                    import { ArrayToQParams } from '../../application/utils/arrayToQParams';
                    import instance from '../../infra/db/instance';
                    //import { AbstractSearch } from './abstractSearch';
                    import { FormatterValidator } from '../../infra/adapters/formatterValidator'
                    import BranchModel from './model';
                    
                    export default class useServiceSearch extends BranchModel {
                        constructor() {
                            super();
                        }
                        async execute(args) {
                    
                    
                            //console.log("branchModel.payload", args);
                            const response = await instance({
                                url: `/branch${ArrayToQParams(args)}&method=search&search=true`,
                                method: "GET"
                            }).catch(r => {
                                //console.log("DEU ERRO: ", r);
                                if (r.response.status == 403)
                                    throw ({
                                        status: 403,
                                        result: "",
                                        resume: "Erro personalizado"
                                    });
                                else
                                    throw ({
                                        result: "",
                                        status: r.response.status,
                                        resume: "deu erro no else"
                                    });
                    
                            })
                    
                            //console.log("~ RESPOSTA API ~", response);
                            return response;
                        }
                        formatting(args) {
                            let res = FormatterValidator.formatting(args, this.initialValues)
                            this.payload = res;
                            return res
                        }
                    
                        async searching() {
                            //console.log("FAZENDO A BUSCA USANDO SERACHING... ")
                            //console.log(this.payload)
                            //console.log(this.initialValues);
                            let where = [];
                    
                            // Object.entries(this.payload[0]).map((item, value) => {
                    
                            // });
                            const d = this.payload[0];
                    
                            
                if (d.branch_id)
                    where.push(["A.branch_id", "IN", d.branch_id.map(item => item.code)]);
                 
                if (d.branch_name)
                    where.push(["A.branch_name", "LIKE", `${d.branch_name}%`]);
                 
                if (d._created_user_id)
                    where.push(["A._created_user_id", "IN", d._created_user_id.map(item => item.code)]);
                 
                if (d._updated_user_id)
                    where.push(["A._updated_user_id", "IN", d._updated_user_id.map(item => item.code)]);
                 
                if (d._created_at_start && d._created_at_end)
                    where.push(["A._created_at", "BETWEEN", [d._created_at_start ,d._created_at_end]]);
                 
                if (d._updated_at_start && d._updated_at_end)
                    where.push(["A._updated_at", "BETWEEN", [d._updated_at_start ,d._updated_at_end]]);
                 
                if (d.branch_status_id)
                    where.push(["A.branch_status_id", "IN", d.branch_status_id.map(item => item.code)]);
                 
                if (d.logo_img)
                    where.push(["A.logo_img", "LIKE", `${d.logo_img}%`]);
                 
                if (d.cell_phone)
                    where.push(["A.cell_phone", "=", d.cell_phone]);
                 
                if (d.phone)
                    where.push(["A.phone", "=", d.phone]);
                 
                if (d.email)
                    where.push(["A.email", "LIKE", `${d.email}%`]);
                
                    
                            //console.log("where: ", where);
                            return {
                                leftJoin: true,
                                where: where,
                                orderBy: "A.branch_id DESC",
                                countAllRows: true,
                                page: 1,
                                limit: 10
                            }
                        }
                    
                    }           
        