import axios from "axios";
import HttpClient from "../axios/httpClientBK";


export const FindOne = {
    Header: (token) => {
        return {
            "Token-Authorization": token,
            "Is-Api": true,
            "Entity-Public-Token": "host.com.br"
        };
    },    
    findById: async (id, token) => {      
        console.clear();
        console.log("`https://cortex.app.br/v1bk240704/subscriber/index.php?subscriber_id=${id}`",`https://cortex.app.br/v1bk240704/subscriber/index.php?subscriber_id=${id}`);          
        // URL do endpoint
        const url = ``; // Observe que você pode usar caminhos relativos com a instância 

        const httpClient = new HttpClient(`https://cortex.app.br/v1bk240704/subscriber/index.php?subscriber_id=${id}`, 5000);
        httpClient.setToken({
            "Token-Authorization": token,
            "Is-Api": true,
            "Entity-Public-Token": "host.com.br"
        });

        return httpClient.get(url)
            .then(responseData => {
                return responseData
            }).catch(error => {
                console.log("erro",error);
                return error.response
            })
    }
}