import React, { useEffect, useState, useRef, useContext } from 'react'

        import CustomDropdown from './CustomDropdown2';
        import useRead from '../domain/services/hookRead';
        import { ReadSearch } from '../domain/services/readSearch';        

        const SelectSingleDbStatus = ({ field, formik,loading, data,options,optionSelected, listener,required, onBlurHandler, labelField, placeholder, label,ref }) => {      
                const [liststatus, isLoadingstatus, isErrorstatus] = useRead({
                    table: "tb_status",
                    src: 'status',
                    where: [["status_id", "!=", "0"],["screen_id","=",data.screen_id]],
                    orderBy: "status_name asc",
                    limit: "50"
                },ReadSearch);
            return (
                <CustomDropdown
                    field={field}
                    label={label??"Status da turma"}
                    options={[liststatus, 'tb_status', 'status_name', 'status_id']} 
                    labelField="name" 
                    loading={isLoadingstatus} 
                    //listener={onListener}
                    optionSelected='status_name' 
                    required={true} 
                    //onBlurHandler={()=> loadConfigCertificate()}
                    placeholder="Status da turma"
                    formik={formik}
                />

            );
        }

        export default SelectSingleDbStatus; 