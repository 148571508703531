import React, { useEffect, useState, useRef, useContext } from 'react'

import CustomDropdown from './CustomDropdown2';
import useRead from '../../src/domain/services/hookRead';
import { ReadSearch } from '../domain/services/readSearch';

const SelectSingleDbCourse = ({ field, formik, loading, options, optionSelected, listener, required, onBlurHandler, labelField, placeholder, label, ref }) => {
    const [listcourse, isLoadingcourse, isErrorcourse] = useRead({
        table: "tb_course",
        src: 'course',
        where: [["course_id", "!=", "0"]],
        orderBy: "course_name asc",
        limit: "50"
    }, ReadSearch);
    return (
        <CustomDropdown
            field={field}
            label={label ?? "Nome do curso"}
            options={[listcourse, 'tb_course', 'course_name', 'course_id']}
            labelField="name"
            loading={isLoadingcourse}
            //listener={onListener}
            optionSelected='course_name'
            required={true}
            //onBlurHandler={()=> loadConfigCertificate()}
            placeholder="informe o nome do curso"
            formik={formik}
        />
    );
}

export default SelectSingleDbCourse; 