        
        import instance from '../../infra/db/instance';
        import { AbstractDelete } from './abstractDelete';
        
        export default class UseServiceDelete extends AbstractDelete {
            constructor(payload) {
                super(payload);
            }
            async delete(data) {
                let newData = data;
                
                console.log("~ * ~ URL ENVIADA A API - DELETE ~ * ~", newData);
                //SE newData NÃO FOR UM ARRAY ENTÃO EXECUTA  CONVERSÃO
                if (typeof newData != "array")
                    newData = [newData];
                const response = await instance({
                    url: `/class`,
                    method: "DELETE",
                    data: newData
                }).catch(r => {
                    console.log("CATCH INSTANCE", r);
                })
        
                console.log("~ RESPOSTA API ~", response);
                return response;
            }
        
        }