import React, { useEffect, useState, useRef, useContext } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { AuthContext } from "../../Contexts/Auth/AuthContext";

import MyButton from '../../components/MyButton'
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';


function Login() {

    const auth = useContext(AuthContext);

    const navigate = useNavigate();
    const params = useParams();
    const { register, handleSubmit, reset, formState } = useForm();
    const [showLoader, setShowLoader] = useState(false)
    const [showReturnLogin, setShowReturnLogin] = useState(false)

    //const [user, setUser] = useState({ email: 'root@singularis.com.br', password: '123456'})
    const { errors } = formState;
    let authToken = localStorage.getItem('authToken')

    async function goLogin(token) {
        setShowLoader(true)

        const isLogged = await auth.signin({ token: token });
        console.log("isLogged", isLogged);
        if (isLogged) {
            navigate('/admin/register/page/show');
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Não foi possível fazer login',
                footer: isLogged.resume.error.error_short_description,
                showConfirmButton: true
            })
            setShowLoader(false)
        }

        setShowReturnLogin(false);
    }

    async function onSubmit(data, e) {
        
        e.preventDefault();
        setShowLoader(true)
        if (data.email && data.password) {

            await auth.signin({ email: data.email, password: data.password })
                .then(r => {
                    console.log("RESPOSTA INDEX", r);
                    if (r.status == 200)
                        navigate('/admin/register/class');
                    if (r.status == 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Não foi possível fazer login',
                            footer: r.resume,
                            showConfirmButton: true
                        })
                        setShowLoader(false)
                    }
                })
                .catch(e => {
                    console.log("signin:: ", e);
                    Swal.fire({
                        icon: 'error',
                        title: 'Não foi possível fazer login',
                        footer: "footer",
                        showConfirmButton: true
                    })
                    setShowLoader(false)
                })


        }
        setShowReturnLogin(false);

        return false;
    }
    useEffect(() => {


        // reset form with user data
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        
        const presence = urlParams.get('presence')
        const token = urlParams.get('token')
        console.log("presence", presence);
        console.log("token", token);
        console.log("authToken", authToken);
        if (presence > 0 && authToken != null) {
            navigate(`/presence/${presence}`);

        } else {
            localStorage.clear();
        }
        /* if(window.location.protocol.replace(/:$/, "") == "https"){
window.location.href='http://cortex.app.br';
        } */
        if (token) {
            /* TEMPORARIO */
            goLogin(token)
        }
        reset({ email: '', password: '' });

    }, []);


    return (
        <>
            <div className="auth-page-wrapper pt-5">

                <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
                    <div className="bg-overlay"></div>

                    <div className="shape">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                        </svg> */}
                    </div>
                </div>


                <div className="auth-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <a href="index.html" className="d-inline-block auth-logo">
                                            {/* <img src="assets/images/logo-light.png" alt="" height="20" /> */}
                                            <span style={{fontSize:'18px'}}>CÓRTEX</span>
                                        </a>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Instituto Consciência e Prosperidade</p>
                                </div>
                            </div>
                        </div>


                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="card mt-4 card-bg-fill">

                                    <div className="card-body p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Bem-vindo!</h5>
                                            <p className="text-muted">Entre com seu email e senha</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <form onSubmit={handleSubmit(onSubmit)} className="validate m-t" id="form-login">
                                                <div className="mb-3">
                                                    <label htmlFor="username" className="form-label">Email</label>
                                                    <input className={`form-control ${errors.email ? 'is-invalid' : ''}`} name="email" {...register('email')} type="email" />
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        <a href="auth-pass-reset-basic.html" className="text-muted">Esqueceu a senha?</a>
                                                    </div>
                                                    <label className="form-label" htmlFor="password-input">Senha</label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <input className={`pe-5 password-input form-control ${errors.password ? 'is-invalid' : ''}`} name="password" {...register('password')} type="password" />
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none" type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <label className="form-check-label" htmlFor="auth-remember-check">Lembre-me</label>
                                                </div>

                                                <div className="mt-4">
                                                    <MyButton
                                                        text="Entrar"
                                                        type="submit"
                                                        loading={showLoader}
                                                        disabled={showLoader}
                                                        btnClass="btn btn-success w-100"
                                                    />
                                                </div>

                                                {/* <div className="mt-4 text-center">
                                                    <div className="signin-other-title">
                                                        <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                                    </div>
                                                    <div>
                                                        <button type="button" className="btn btn-primary btn-icon waves-effect waves-light"><i className="ri-facebook-fill fs-16"></i></button>
                                                        <button type="button" className="btn btn-danger btn-icon waves-effect waves-light"><i className="ri-google-fill fs-16"></i></button>
                                                        <button type="button" className="btn btn-dark btn-icon waves-effect waves-light"><i className="ri-github-fill fs-16"></i></button>
                                                        <button type="button" className="btn btn-info btn-icon waves-effect waves-light"><i className="ri-twitter-fill fs-16"></i></button>
                                                    </div>
                                                </div> */}
                                            </form>
                                        </div>
                                    </div>

                                </div>


                                {/* <div className="mt-4 text-center">
                                    <p className="mb-0">Don't have an account ? <a href="auth-signup-basic.html" className="fw-semibold text-primary text-decoration-underline"> Signup </a> </p>
                                </div> */}

                            </div>
                        </div>

                    </div>

                </div>



                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0 text-muted">&copy;
                                        <script>document.write(new Date().getFullYear())</script> Córtex <i className="mdi mdi-heart text-danger"></i> ICP Versão: 2407015
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

            </div>

            {/* outro */}


        </>
    );
}

export default Login;
