import React, { memo } from 'react';
import { InputText } from 'primereact/inputtext';

const CustomInputTextSEARCH = ({ field, label, args, readonly, dataSet,onChange, required, className,placeholder }) => {
  
  return (
    <>
      <label htmlFor={field}>{label}</label>
      <InputText
        id={field}
        value={dataSet[field]}
        className={`form-control pl-1`}
        name={field}
        placeholder={placeholder}
        style={{textTransform:'uppercase'}}
        onChange={(e) => onChange(e)}
        prefix="+055"
      />
      
    </>
  );
};

export default memo(CustomInputTextSEARCH);
