import React, { useEffect, useState, useContext } from 'react'
import { ErrorMessage, useFormik } from 'formik';
import UseServicePutSubscriber from './useServicePut.js';
import { ContentContext } from '../../Contexts/Contents/Context.js';
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { useNavigate} from 'react-router-dom'

import { useLocation } from 'react-router-dom';

function UCCreateSubscriber() {
    const location = useLocation();
    const navigate = useNavigate();

    const { provider_current_client } = useContext(AuthContext)
    const { setListData, editData, action, setAction, setEditData, listData, getBroadcast } = useContext(ContentContext)

    const [onResume, setOnResume] = useState({});
    const [getClassId, setClassId] = useState();
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [closeModal1, setCloseModal] = useState(null);

    const initialValues = {
        "subscriber_name": "",
        "dt_birth": "",
        "cpf": "",
        "email": "",
        "whatsapp_number": "",
        "seller_id": "",
        "manager_id": "",
        "class_id": "",
        "client_id": ""
    };

    function substituirElemento(array, objetoSubstituto, indiceAlvo) {


        return array.map((elemento, indice) => {

            // Se o índice for o índice alvo, substitua o objeto
            if (elemento[indiceAlvo] === objetoSubstituto[0][indiceAlvo]) {
                console.log("elemento[indiceAlvo] === objetoSubstituto[0][indiceAlvo]", elemento[indiceAlvo], objetoSubstituto[0][indiceAlvo]);
                return objetoSubstituto[0];
            }
            // Caso contrário, mantenha o objeto original
            return elemento;
        });
    }
    const save = async (r) => {
        setAction("patch")
        r.preventDefault()
        console.log(formik.values);
        const subscribers = formik.values.subscribers.map((el) => {
            return { subscriber_id: el, seller_id: formik.values.seller_id };
        })
        const formattingCreate = new UseServicePutSubscriber();
        let msg = {};
        //let formatted = formattingCreate.formatting(subscribers);
        console.log("subscribers|formatted", subscribers);
        let created = await formattingCreate.execute("patch", subscribers);
        if(created.status == 200)
        {
            setCloseModal(true);
            alert("Entre na turma para atualizar a listagem.")
        }
    }

    const formik = useFormik({
        initialValues,
        validate: (data) => {
            let errors = {};
            const useServicePutSubscriber = new UseServicePutSubscriber();
            let erro = useServicePutSubscriber.validation([data]);
            errors = erro;
            return errors
        },
        onSubmit: async (payload, { setSubmitting }) => {
            console.log("payload", payload);
            if (action == "post") {
                payload.client_id = provider_current_client.client_id;
                payload.class_id = getClassId;
            }
            console.log("Agora vai " + action, payload);
            const formattingCreate = new UseServicePutSubscriber();
            let msg = {};
            let formatted = formattingCreate.formatting([payload]);
            console.log("Valores formatados:", formatted);

            setIsSubmiting(true)

            /* try {

                let created = await formattingCreate.execute(action, formatted);

                console.log("created:");
                console.log(created);
                let isTrue = false;
                if (action == "post") {
                    if (created.data.result.success.length > 0) {
                        setListData(prev => [...prev, created.data.result.success[0]]);
                        msg = { summary: "Sucesso!", detail: "Registro criado com sucesso.", severity: "success" };
                        isTrue = true;
                    } else {
                        msg = { summary: "Atenção!", detail: "Erro ao cadastrar o aluno: "+created.data.result.fail[0]._fail[0].description, severity: "error" };
                    }
                }

                if (action == "patch") {
                    setListData(substituirElemento(listData, created.data.result.success, "subscriber_id"));
                    msg = { summary: "Sucesso!", detail: "Registro atualizado com sucesso.", severity: "success" };
                }

                setOnResume(msg);
                if (isTrue == true) {
                    formik.resetForm();
                }
                setIsSubmiting(false)
            } catch (error) {
                console.log("error", error);
                setOnResume({ summary: "Falha na comunicação", detail: "Houve um erro entre o servidor e a aplicação. Tente novamente em instantes.", severity: "error" });
                setIsSubmiting(false)
            } */
            setSubmitting(false);
        },
        validateOnChange: false,
        validateOnBlur: true,
    })
    const handleClickCancel = (button) => {
        setAction("post")
        setEditData(null)
        formik.resetForm();
    }

    useEffect(() => {
        //console.clear();
        console.log("~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [location] ::: pega o patch editData ::: ~~~~~~~~~~~~~~~~~~~~~~", location);
        if (location.state != null) {
            const params = location.pathname.split("/");
            setClassId(params[params.length - 1]);

            if (params[params.length - 1] == "patch") {
                setClassId(params[params.length - 2]);

                const data = location.state.stateData;
                setAction("patch")
                setEditData(data)
                formik.setValues(data);
            }
        }
    }, [location])

    useEffect(() => {

        console.log("~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [ErrorMessage] ::: pega o patch editData ::: ~~~~~~~~~~~~~~~~~~~~~~");
        console.log(ErrorMessage);
    }, [ErrorMessage])

    useEffect(() => {
        if (getBroadcast != null) {
            console.log('%c ~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [getBroadcast] ::: fica de olho no listener ::: ~~~~~~~~~~~~~~~~~~~~~~', 'color: #000; background-color: green;');
            console.log(getBroadcast);
            setAction("post")
            formik.resetForm()
        }
    }, [getBroadcast])

    return {
        formik,
        onResume,
        isSubmiting,
        setIsSubmiting,
        editData,
        action,
        closeModal1,
        save,
        setCloseModal,
        handleClickCancel
    }
}

export default UCCreateSubscriber;
