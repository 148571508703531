import SingMask from '../../utils/SingMask';
import axios from 'axios';

export default class GeneratePdf {

static download = async (el,dadosClass) => {  
        console.clear();              
        
        console.log("LOCATION",dadosClass);
        
        
        el.address = dadosClass.location_description;
        el.date = `${SingMask.toDate(dadosClass.dt_start, "dmY")} a ${SingMask.toDate(dadosClass.dt_end, "dmY")} - 7h57`;
        /* el.address = `Bahia Plaza Resort - Rodovia BA-099, Estrada do Coco, km 8 Condomínio Porto Busca Vida - Catu de Abrantes - Salvador-BA`;
        el.date = `03/08/2024 a 04/08/2024 - 7h57`; */        

        let data = {
            "page": 2,
            "per_page": 6,
            "total": 12,
            "total_pages": 2,
            "download": true,
            "configs": {
                "template": "subscription_event"
            },
            "content": {
                "body": [
                    el
                ]
            },
            "support": {
                "url": "https://reqres.in/#support-heading",
                "text": "To keep ReqRes free, contributions towards server costs are appreciated!"
            },
            "configs": {
                "template": "ingresso",
                "outputFilename": el.subscriber_name.replace(/\s/g, '-'),
                "type": "pdf",
                "qrcodeOrigin": "`${el.cpf}${el.ingresso_id}`",
                "event_name": "Nome do evento",
                "date": "`06/11/2023`",
                "locale": "`Imperatriz-MA`"
            },
            "qrcode": { "qrcodeOrigin": false }
        }
        console.log("data",data);
        await axios
        //.post(`${process.env.REACT_APP_SERVER_PDF}/generateReport?download=true`, data, {
            .post(`https://singularis.com.br/pdfcreator/generateReport?download=true`, data, {
                responseType: "blob", // this is important!
                headers: { Authorization: "sometoken" },
            })
            .then(response => {
                
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data.configs.outputFilename + "." + data.configs.type);
                document.body.appendChild(link);
                link.click();
            })
            .catch(r => {
                console.log("Houve algum erro", r);
            })
        
    }
} 