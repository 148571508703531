  import React, { useEffect, useState, useRef, useContext } from 'react'

        import CustomDropdown from './CustomDropdown2';
        import useRead from '../../src/domain/services/hookRead';
        import { ReadSearch } from '../domain/services/readSearch';        

        const SelectSingleDbSeller = ({ field, formik,loading, options,optionSelected, listener,required, onBlurHandler, labelField, placeholder, label,ref,session }) => {      
            
                const [listseller, isLoadingseller, isErrorseller] = useRead({
                    table: "tb_seller",
                    src: 'seller',
                    where: [],
                    orderBy: "seller_name asc",
                    leftJoin: true,
                    limit: "50"
                },ReadSearch);
            return (
                <CustomDropdown
                    field={field}
                    label={label??"Líder responsável"}
                    options={[listseller, 'tb_seller', 'seller_name', 'seller_id']} 
                    labelField="name" 
                    loading={isLoadingseller} 
                    //listener={onListener}
                    optionSelected='seller_name' 
                    required={true} 
                    //onBlurHandler={()=> loadConfigCertificate()}
                    placeholder=""
                    formik={formik}
                />

            );
        }

        export default SelectSingleDbSeller;   