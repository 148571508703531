import React, { useEffect, useState, useRef, useContext } from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { AuthContext } from "../../Contexts/Auth/AuthContext";

import MyButton from '../../components/MyButton'
//import withReactContent from 'sweetalert2-react-content'
import { useForm } from "react-hook-form";

import { Patch } from '../../infra/user/Patch';

import { FindOne } from '../../infra/user/FindOne';
import SingMask from '../../utils/SingMask';
import { CustonSwal } from '../../components/Swal';

function Presence() {
    const params = useParams();
    const auth = useContext(AuthContext);
    const { register, handleSubmit, reset, formState } = useForm();
    const [getData, setData] = useState({})//Está sempre será carregada e populada para evitar uma nova busca no banco de dados quando, por exemplo um novo registro é acrescido.
    const [getStatus, setStatus] = useState(false)
    let authToken = localStorage.getItem('authToken')

    const confirmePresence = () => {

        console.log("sessao:", authToken);
        const payload = {
            "subscriber_status_id": "51399",
        }

        //CREATE
        //if (getStatus == true) {


        try {
            Patch.patchById(params.subscriber_id, payload, authToken)
                .then(res => {
                    console.log("res", res);
                    if (res.status == 201) {
                        CustonSwal(4031);
                        setStatus((prev) => !prev);
                    }
                    else {
                        CustonSwal(502);
                        setStatus(false);
                    }
                    console.log("res", res);
                })
        } catch (error) {
            console.log("CATCH FRONT: ", error.response.data);
        }
        //}
    }

    useEffect(() => {
        // console.log(params);

        /* TESTE */
        try {
            FindOne.findById(params.subscriber_id, authToken)
                .then(res => {
                    console.log("res", res.result[0]);
                    setData(res.result[0])
                    if (res.result[0].subscriber_status_id == 51399)
                        setStatus(false)
                    else {
                        setStatus(true)
                        confirmePresence();
                    }

                })
        } catch (error) {
            console.log("CATCH FRONT: ", error);
        }

        /*  
        const newSubscriber = {
            "subscriber_name": "TESTE LAST",
            "whatsapp_number": "(15) 9 92791-4259",
            "manager_id": "11",
            "seller_id": "400",
            "client_id": "200002",
            "class_id": "119"
        }

        //CREATE
        try {
             SaveUserOk.create(newSubscriber, authToken)
                 .then(res => {
                     if (res.status == 201)
                     console.log('ok')
                     else if (res.status == 401)
                     console.log('não autorizado')
                     else if (res.status == 403)
                     console.log('Proibido')
                     else
                         console.log("res", res);
                     console.log("res", res);
                 })
         } catch (error) {
             console.log("CATCH FRONT: ", error.response.data);
         }
 
  */

    }, [])
    return (
        <>
            <div className="blankpage-form-field">
                <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
                    <div className="page-logo-link press-scale-down d-flex align-items-center">
                        <img src="/assets/img/logo.png" alt="SmartAdmin WebApp" aria-roledescription="logo" />
                        <span className="page-logo-text mr-1">CÓRTEX - Versão Beta - v24.02.156</span>
                    </div>
                </div>
                <div className="card p-4 border-top-left-radius-0 border-top-right-radius-0">




                </div>
                <div className="blankpage-footer text-center">

                </div>
            </div>
            {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
            <div className="auth-page-wrapper pt-5">

                <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
                    <div className="bg-overlay"></div>

                    <div className="shape">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                        </svg> */}
                    </div>
                </div>


                <div className="auth-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <a href="index.html" className="d-inline-block auth-logo">
                                            {/* <img src="assets/images/logo-light.png" alt="" height="20" /> */}
                                            <span style={{ fontSize: '18px' }}>CÓRTEX</span>
                                        </a>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Instituto Consciência e Prosperidade</p>
                                </div>
                            </div>
                        </div>


                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="card mt-4 card-bg-fill">

                                    <div className="card-body p-4">
                                        <div className="text-center mt-2">
                                            <div className="form-group">
                                                {/* <input type="email" id="username" className="form-control" placeholder="your id or email" value="drlantern@gotbootstrap.com"/> */}
                                                <h3 className="text-center" htmlFor="rememberme"><b>PRESENÇA</b></h3>
                                                <hr />
                                                <h4 className="" htmlFor="rememberme"><b>Nome</b><br />{getData.subscriber_name}</h4>

                                                <h4 className="" htmlFor="rememberme"><b>Nº do ingresso: </b><br />{getData.subscriber_id}</h4>
                                                <h4 className="" htmlFor="rememberme"><b>Nº do telefone: </b><br />{SingMask.toPhone(getData.whatsapp_number)}</h4>
                                            </div>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <form className="validate m-t" id="form-login">
                                                <div className="mb-3" style={{textAlign: 'center'}}>
                                                    {
                                                        getStatus == true ?

                                                            <MyButton
                                                                text="Confirmar presença"
                                                                type="submit"
                                                                // loading={showLoader}
                                                                // disabled={showLoader}
                                                                btnClass="btn btn-success btn-block"
                                                                onClick={() => confirmePresence()}
                                                            /> :
                                                            <MyButton
                                                                text="Presença confirmada"
                                                                type="submit"
                                                                // loading={showLoader}
                                                                disabled="true"
                                                                btnClass="btn btn-warning btn-block"
                                                            />
                                                    }

                                                </div>



                                                {/* <div className="mt-4 text-center">
                                                    <div className="signin-other-title">
                                                        <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                                    </div>
                                                    <div>
                                                        <button type="button" className="btn btn-primary btn-icon waves-effect waves-light"><i className="ri-facebook-fill fs-16"></i></button>
                                                        <button type="button" className="btn btn-danger btn-icon waves-effect waves-light"><i className="ri-google-fill fs-16"></i></button>
                                                        <button type="button" className="btn btn-dark btn-icon waves-effect waves-light"><i className="ri-github-fill fs-16"></i></button>
                                                        <button type="button" className="btn btn-info btn-icon waves-effect waves-light"><i className="ri-twitter-fill fs-16"></i></button>
                                                    </div>
                                                </div> */}
                                            </form>
                                        </div>
                                    </div>

                                </div>


                                {/* <div className="mt-4 text-center">
                                    <p className="mb-0">Don't have an account ? <a href="auth-signup-basic.html" className="fw-semibold text-primary text-decoration-underline"> Signup </a> </p>
                                </div> */}

                            </div>
                        </div>

                    </div>

                </div>



                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0 text-muted">&copy;
                                        <script>document.write(new Date().getFullYear())</script> Córtex <i className="mdi mdi-heart text-danger"></i> ICP Versão: 240704010
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

            </div>

        </>
    );
}

export default Presence;
