        
        import React from 'react';

        const Breadcrumb = () => {

            return (
                <>
                    {/* start page title */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
                                <h4 className="mb-sm-0">Inscrições</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">Evento</a></li>
                                        <li className="breadcrumb-item active">Inscritos</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* end page title */}
                </>
            )
        }
        export default Breadcrumb