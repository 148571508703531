import axios from "axios";
import { loadItemFromLocalStorage } from "../../utils/localStorageUser";

const instance = axios.create({
  //baseURL : 'http://127.0.0.1:1004/',
  baseURL: "https://cortex.app.br/api/v1",
  //baseURL: "https://alisonbritoeng.com.br/api/v1",
  //baseURL: process.env.REACT_APP_HOST_API_CLIENT,
  headers: {
    //"Token-Authorization": localStorage.getItem('authToken'),
    //"Token-Authorization": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkVyaXN2YWxkbyBBcmHDumpvIiwidXNlcl9pZCI6IjEiLCJpYXQiOjE1MTYyMzkwMjJ9.LPgU5TKIBrrXhVfE3t3APo1NLsJ4pt6Ygz0keuyFnuI",
    "Is-Api": true,
    "Entity-Public-Token": "host.com.br"
  }
});

export default instance;