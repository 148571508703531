import React, { useEffect, useState, useRef, useContext } from 'react'

import CustomDropdown from './CustomDropdown2';
import useRead from '../../src/domain/services/hookRead';
import { ReadSearch } from '../domain/services/readSearch';

const SelectSingleDbGender = ({ field, formik, loading, options, optionSelected, listener, required, onBlurHandler, labelField, placeholder, label, ref }) => {
    const [listgender, isLoadinggender, isErrorgender] = useRead({
        table: "tb_gender",
        src: 'gender',
        where: [["gender_id", "!=", "0"]],
        orderBy: "gender_name asc",
        limit: "50"
    }, ReadSearch);
    return (
        <CustomDropdown
            field={field}
            label={label ?? "Gênero"}
            options={[listgender, 'tb_gender', 'gender_name', 'gender_id']}
            labelField="name"
            loading={isLoadinggender}
            //listener={onListener}
            optionSelected='gender_name'
            required={true}
            //onBlurHandler={()=> loadConfigCertificate()}
            placeholder="Gênero"
            formik={formik}
        />

    );
}

export default SelectSingleDbGender;   