import React, { useEffect, useState, useRef, useContext } from 'react'

        import CustomDropdown from './CustomDropdown2';
        import useRead from '../../src/domain/services/hookRead';
        import { ReadSearch } from '../domain/services/readSearch';        

        const SelectSingleDbLocation = ({ field, formik,loading, options,optionSelected, listener,required, onBlurHandler, labelField, placeholder, label,ref }) => {      
                const [listlocation, isLoadinglocation, isErrorlocation] = useRead({
                    table: "tb_location",
                    src: 'location',
                    where: [["location_id", "!=", "0"]],
                    orderBy: "location_name asc",
                    limit: "50"
                },ReadSearch);
            return (
                <CustomDropdown
                    field={field}
                    label={label??"Local do curso"}
                    options={[listlocation, 'tb_location', 'location_name', 'location_id']} 
                    labelField="name" 
                    loading={isLoadinglocation} 
                    //listener={onListener}
                    optionSelected='location_name' 
                    required={true} 
                    //onBlurHandler={()=> loadConfigCertificate()}
                    placeholder="Local do curso"
                    formik={formik}
                />

            );
        }

        export default SelectSingleDbLocation; 