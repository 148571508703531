import instance from '../../infra/db/instance';
import { AbstractDelete } from './abstractDelete';

export default class useServiceDelete extends AbstractDelete {
    constructor(payload) {
        super(payload);
    }
    async delete(data) {
        let newData = data;

        console.log("~ * ~ URL ENVIADA A API - UseServicePearsonDelete::DELETE ~ * ~", newData,typeof newData);
        //SE newData NÃO FOR UM ARRAY ENTÃO EXECUTA  CONVERSÃO
        if (typeof newData != "array" && typeof newData != "object")
            newData = [newData];
        const response = await instance({
            url: `/pearson`,
            method: "DELETE",
            data: newData
        }).catch(r => {
           return r
        })

        console.log("~ RESPOSTA API ~", response);
        return response;
    }

}