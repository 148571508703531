

import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

/* COMPONENTES */
import MyButton from '../../components/MyButton'

/* AUXILIARES */
import Alert from '../../components/Alert.jsx';
import { AuthContext } from '../../Contexts/Auth/AuthContext.js';

import Permission from '../../utils/Permission.js';
import UCCreateSubscriber from './useCaseCreate.js';
import CustomInput from '../../components/CustomInput.jsx';
import ShowToast from '../../components/Toast.jsx';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'

/* select personalizados */
//import InputUser from '../../components/inputUser.jsx';
import SelectSingleDbStatus from '../../components/selectSingleDbStatus.jsx';
import SelectSingleDbSeller from '../../components/selectSingleDbSeller.jsx';
import SelectSingleDbManager from '../../components/selectSingleDbManager.jsx';


const FragmentFormNewSeller = forwardRef((props, ref) => {
    const { provider_session } = useContext(AuthContext)
    const [isShowModalChange, setModalChange] = useState(props.isOpen);
    const [isCheckBoxValues, setCheckBoxValues] = useState([]);
    

    const modalRef = useRef();
    const buttonRef = useRef(null);
    
    /* FUNÇÃO RESPONSÁVEL PELA EDIÇÃO, POPULANDO O PAYLOAD NO FORMIK */
    useImperativeHandle(ref, () => ({
        open: (param) => {
            formik.setFieldValue("subscribers",props.checkboxValues)
            if (modalRef.current) {                
                const modal = new window.bootstrap.Modal(modalRef.current);
                modal.show();
            }
        }
    }));
    
    const openModal = () => {
        if (modalRef.current) {
            const modal = new window.bootstrap.Modal(modalRef.current);
            modal.show();
        }
    };

    const closeModal = () => {
        buttonRef.current.click(); // Simula o clique no botão
        setModalChange(false)
    };
    const {
        formik,
        onResume,
        isSubmiting,
        editData,
        action,
        save,
        closeModal1,
        handleClickCancel
    } = UCCreateSubscriber();

    useEffect(() => {
        console.log("useEffect onResume", onResume);
        if (onResume != null)
            closeModal()
    }, [onResume])
    useEffect(() => {
        
            closeModal()
    }, [closeModal1])

    useEffect(() => {
        console.log("useEffect onResume", onResume, editData);
        if (editData != null)
            openModal();
    }, [editData])

    useEffect(() => {
        console.log("xxxxx",isShowModalChange);          
        if (isShowModalChange) {  
            console.log("xxxxx",isShowModalChange);          
                const modal = new window.bootstrap.Modal(modalRef.current);
                modal.show();            
        }else{
            buttonRef.current.click(); // Simula o clique no botão
        }
        //openModal();
    }, [isShowModalChange])

    return (
        <>
            {
                isSubmiting &&
                <CircleSpinnerOverlay
                    loading={true}
                    zIndex="9999"
                    message="Carregando..."
                    overlayColor="rgba(0,153,255,0.5)"
                />
            }
            {
                /* action == "patch" && editData == null ?
                    <Alert text={<><strong>Sinto muito!!</strong> Não foi enviado uma informação para edição</>} icon="" className="danger" iconAlign="left" />
                    : (
                        editData != null &&
                        <>

                        </>
                    ) */
            }
            <div className="modal fade" id="modalAjuste" ref={modalRef} tabindex="-1" aria-labelledby="exampleModalgridLabel2">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalgridLabel2">
                                {
                                    action == "patch" ? "Ajuste" : "Ajuste"
                                }
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={buttonRef} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={save} className="validate m-t needs-validation" noValidate id="form-create-update">
                                <div className="panel-content">
                                    <div className="form-row">
                                        <div className="col-md-auto mb-3">
                                            <SelectSingleDbSeller field="seller_id" label="Líder responsável" formik={formik} session={provider_session} />
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-content border-faded border-left-0 modal-footer border-right-0 border-bottom-0 d-flex flex-row   bg-faded">
                                    {
                                        action == "patch" &&
                                        <MyButton
                                            name="btnCancel"
                                            text="Cancelar"
                                            type="button"
                                            onClick={(el) => handleClickCancel("btnCancel")}
                                            icon="fal fa-times"
                                            btnClass="btn btn-default ml-auto" />
                                    }
                                  
                                    <MyButton
                                        name="btnSave"
                                        text="Salvar"
                                        type="submit"
                                        loading={isSubmiting}
                                        icon="fal fa-save"
                                        btnClass="btn btn-primary ml-2" />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ShowToast resume={onResume}></ShowToast>
        </>
    );
})

export default FragmentFormNewSeller;
