import React, { useEffect, useState, useRef, useContext } from 'react'

import CustomDropdown from './CustomDropdown2';
import useRead from '../../src/domain/services/hookRead';
import { ReadSearch } from '../domain/services/readSearch';

const SelectSingleDbManager = ({ field, formik, loading, options, optionSelected, listener, required, onBlurHandler, labelField, placeholder, label, ref }) => {
    const [listmanager, isLoadingmanager, isErrormanager] = useRead({
        table: "tb_manager",
        src: 'manager',
        where: [["A.user_level_id", "=", "200"]],
        //orderBy: "A.manager_name asc", "select B.status_name as manager_status_name,C.user_level_name,D.manager_name,A.* from tb_user_association as A 
        /* LEFT JOIN tb_status AS B ON(B.status_id = A.user_association_status_id) 
        LEFT JOIN tb_user_level AS C ON(C.user_level_id = A.user_level_id) 
        LEFT JOIN tb_manager AS D ON(D._owner_user_id = A.user_id)  
        where A.user_association_status_id != 0 and user_level_id = 200 and A.client_id = 200003 limit 50 offset 0" */
        leftJoin:true,
        limit: "50"
    }, ReadSearch);
    return (
        <CustomDropdown
            field={field}
            label={label ?? "Gestor responsável"}
            options={[listmanager, 'tb_manager', 'manager_name', 'user_id']}
            labelField="name"
            loading={isLoadingmanager}
            //listener={onListener}
            optionSelected='manager_name'
            required={true}
            //onBlurHandler={()=> loadConfigCertificate()}
            placeholder="Gestor responsável"
            formik={formik}
        />

    );
}

export default SelectSingleDbManager;   