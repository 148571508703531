import axios from "axios";
import HttpClient from "../axios/httpClient";


export const Patch = {
    Header: (token) => {
        return {
            "Token-Authorization": token,
            "Is-Api": true,
            "Entity-Public-Token": "host.com.br"
        };
    },
    patchById: async (id, payload, token) => {
        // URL do endpoint
        const url = ``; // Observe que você pode usar caminhos relativos com a instância
        if (typeof id == 'number' && !isNaN(id))
            throw "Id obrigatório"
 
        const httpClient = new HttpClient(`https://cortex.app.br/v1bk240704/subscriber/index.php?action=post`, 5000);

        httpClient.setToken({
            "Token-Authorization": token,
            "Is-Api": true,  
            "Entity-Public-Token": "host.com.br"
        });

        if (id > 0) {
            return httpClient.patch(url, {subscriber_id:id})
                .then(responseData => {
                    return responseData
                }).catch(error => {
                    console.log("erro", error);
                    return error.response
                })
        } else {
            throw "Id obrigatório"
        }
    }
}