import React, { memo, useState } from 'react';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';
import { FormatterValidator } from '../infra/adapters/formatterValidator';

const CustomInputDateTime = ({ field, label, args, onBlur, readonly, formik, onlyNumbers, onKeyUp, required, autoComplete }) => {
  const [getMask, setMask] = useState(args.mask);
  const [value, setValue] = useState('');
  const [getDoc, setDoc] = useState('');
  let isArray = false;

  function findClosestValue(index, obj) {
    let closestKey = null;
    let closestDiff = Infinity;

    for (const key in obj) {
      const diff = Math.abs((index + 1) - parseInt(key));
      if (diff < closestDiff) {
        closestDiff = diff;
        closestKey = key;
      }
    }

    return obj[closestKey];
  }

  const handleFocus = (e) => {

    let newMask = args.mask;
    if (args.mask.indexOf("[") >= 0) {
      isArray = true
      let array = args.mask.slice(1, -1).split(",");
      const obj = Object.fromEntries(array.map(item => [item.replace(/\D/g, '').length, item]));
      let total = e.target.value.replace(/\D/g, '').length;
      newMask = findClosestValue(total, obj);
    }

    setMask(newMask);
  }

  const handleFieldOnBlur = (e) => {
    let error = false;
    try {
      //console.log("args.format", args.format);
      if (args.format == "date") {
        error = FormatterValidator.formatDate(e.target.value)
        error = FormatterValidator.validateDate(error)
        //console.log("ERROR: ", error);
      }
      //if(!!error)
    } catch (error) {
      //console.log("error "+field, error);
      formik.errors[field] = error.description

    }   

  }

  const handleFieldChange = (e) => {
    ////console.log("handleFieldChange",e.target);
    let inputValue = e.target.value;
    let resultArray = "";

    if (onKeyUp)
      onKeyUp(e.target.value)


    if (args.mask.indexOf("[") >= 0) {
      isArray = true
      resultArray = args.mask.slice(1, -1).split(",");
    }

    /* SE FOR UM CAMPO CPF/CNPJ */
    if (args?.doc) {
      const array = resultArray;

      const obj = Object.fromEntries(array.map(item => [item.replace(/\D/g, '').length, item]));
      let total = inputValue.replace(/\D/g, '').length;
      let newMask = findClosestValue(total, obj);
      let proximo = newMask.replace(/\D/g, '').length;

      if (formik.values.doc.replace(/\D/g, '').length == total && total == proximo && e.originalEvent.type == "keypress") {
        let m = findClosestValue(proximo + 1, obj);
        setMask(m);
      } else if (total <= proximo && e.originalEvent.type == "keypress") {
        setMask(newMask);
      } else if (total >= proximo && e.originalEvent.type == "keypress") {
        setMask(newMask);
      } else {
        setMask(newMask);
      }
    }
    formik.setFieldValue(field, e.target.value);

  };

  const dateUsToBr = (date)=>{
    //console.log("CHAMOU AQUI",date);

    if(date!=undefined)
    {
      const parts = date.split('-');
        const formattedDate = parts.reverse().join('/'); // Inverte a ordem para aaaa-mm-dd
        return formattedDate;
    }
  }

  // faz a verificação pra saber se o componente sera obrigatorio ou não
  let isRequired = required || formik.errors[field] ? classNames({ 'is-invalid': formik.touched[field] && formik.errors[field] }) : "";

  return (
    <>
      <label htmlFor={field}>{label}</label>
      <InputMask
        id={field}
        value={dateUsToBr(formik.values[field])}
        className={`form-control ${isRequired} pl-1`}
        name={field}
        //mask={formik.values.doc.replace(/\D/g, '').length <= 7 ? '999.999.999-99' : '99.999.999/9999-99'}
        readOnly={readonly || args?.action === "view" ? true : false}
        onBlur={formik.handleBlur}
        onChange={handleFieldChange}
        autoComplete={autoComplete ?? "on"}
        mask={getMask}
        onFocus={handleFocus}
        //slotChar="_"
        data-testid="CustomInputDateTime"
      />
      {formik.touched[field] && formik.errors[field] && <div className='invalid-feedback'>{formik.errors[field]}</div>}

    </>
  );
};

export default memo(CustomInputDateTime);
