import React, { useEffect, useState, useRef, useContext } from 'react'

import CustomDropdown from './CustomDropdown2';
import useRead from '../../src/domain/services/hookRead';
import { ReadSearch } from '../domain/services/readSearch';

const SelectSingleDbCertificateConfig = ({ field, formik, loading, options, optionSelected, listener, required, onBlurHandler, labelField, placeholder, label, ref }) => {
    const [listcertificate_config, isLoadingcertificate_config, isErrorcertificate_config] = useRead({
        table: "tb_certificate_config",
        src: 'certificate_config',
        where: [["certificate_config_id", "!=", "0"]],
        orderBy: "certificate_config_name asc",
        limit: "50"
    }, ReadSearch);
    return (
        <CustomDropdown
            field={field}
            label={label ?? "Config. certificado ICP"}
            options={[listcertificate_config, 'tb_certificate_config', 'certificate_config_name', 'certificate_config_id']}
            labelField="name"
            loading={isLoadingcertificate_config}
            //listener={onListener}
            optionSelected='certificate_config_name'
            required={true}
            //onBlurHandler={()=> loadConfigCertificate()}
            placeholder="Config. certificado ICP"
            formik={formik}
        />

    );
}

export default SelectSingleDbCertificateConfig; 