   
        export default class Model {    
            constructor() {    
                this.error = [];
        
                if (new.target === Model) {
                    throw new Error('Não é possível instanciar uma classe abstrata diretamente.');
                }
        
                this.initialValues = {
                    
            "class_id": {
                onCreate: "0",
   type: "int"
                }, 
            "class_number": {
                onCreate: "1",
 formatter: ["formatterNumber"],
  type: "smallint"
                }, 
            "course_id": {
                onCreate: "1",
   type: "int"
                }, 
            "vacancy": {
                onCreate: "1",
 formatter: ["formatterNumber"],
  type: "tinyint"
                }, 
            "dt_start": {
                onCreate: "1",
 formatter: ["formatterDate"],
 mask: "dmY",
 type: "date"
                }, 
            "dt_end": {
                onCreate: "1",
 formatter: ["formatterDate"],
 mask: "dmY",
 type: "date"
                }, 
            "client_id": {
                onCreate: "0",
 formatter: ["formatterNumber"],
  type: "int"
                }, 
            "price_cash": {
                onCreate: "1",
 formatter: ["formatterDecimal2"],
 mask: "BRL2",
 type: "decimal"
                }, 
            "price_split": {
                onCreate: "1",
 formatter: ["formatterDecimal2"],
 mask: "BRL2",
 type: "decimal"
                }, 
            "workload": {
                onCreate: "1",
 formatter: ["formatterInteger"],
  type: "smallint"
                }, 
            "observation": {
                   type: "text"
                }, 
            "location_id": {
                onCreate: "1",
 formatter: ["formatterNumber"],
  type: "int"
                }, 
            "class_modality_id": {
                onCreate: "1",
 formatter: ["formatterNumber"],
  type: "tinyint"
                }, 
            "special_permission_arr": {
                   type: "varchar"
                }, 
            "special_command_id": {
                   type: "smallint"
                }, 
            "_created_user_id": {
                onCreate: "0",
   type: "int"
                }, 
            "_updated_user_id": {
                onCreate: "0",
   type: "int"
                }, 
            "_created_at": {
                onCreate: "0",
 formatter: ["formatterDate","formatterNumber"],
 mask: "dmY",
 type: "datetime"
                }, 
            "_updated_at": {
                onCreate: "0",
 formatter: ["formatterDate"],
 mask: "dmY",
 type: "datetime"
                }, 
            "class_status_id": {
                onCreate: "0",
 formatter: ["formatterNumber"],
  type: "smallint"
                }, 
            "city_id": {
                onCreate: "1",
 formatter: ["formatterNumber"],
  type: "smallint"
                }, 
            "student_certificate_config_id": {
                onCreate: "1",
 formatter: ["formatterNumber"],
  type: "int"
                }, 
            "icp_certificate_config_id": {
                onCreate: "1",
 formatter: ["formatterNumber"],
  type: "int"
                }
                };
            }
            validate() {
                throw new Error('Método "validate" deve ser implementado pelas subclasses.');
            }
            create() {
                throw new Error('Método "create" deve ser implementado pelas subclasses.');
            }
            execute() {
                throw new Error('Método "execute" deve ser implementado pelas subclasses.');
            }
        }
        