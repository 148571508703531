export class AbstractReadSearch {
    constructor(payload) {
        if (new.target === AbstractReadSearch) {
            throw new Error('Não é possível instanciar uma classe abstrata diretamente.');
        }      
        this.payload = payload
    }
    
    execute() {
        throw new Error('Método "execute" deve ser implementado pelas subclasses.');
    }    
}