import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
// type={type} field={field} required={required} placeholder={placeholder} label={label}  formik={formik} args={args} 
const CustomInputMonetarySEARCH = ({ field, label, required, readonly, args,dataSet,onChange }) => {
    const handleFieldChange = (e) => {
        let valor = e.value != null ? e.value.toString() : e.value;
//        formik.setFieldValue(field, valor);
    };

    const configs = {
        mode: 'currency',
        currency: 'BRL',
        locale: 'pt-br',
    };
    const options = args && args.currency
        ? {
            mode: args.mode,
            currency: args.currency,
            locale: args.locale,
        }
        : configs;
    const toReal = (value) => {
        if (value)
            return parseFloat(value)

    }

    return (
        <>
            <label htmlFor={field}>{label}</label>
            <InputNumber
                id={field}
                value={dataSet[field]}
                className={`form-control pl-1`}
                name={field}                            
                onValueChange={(e) => handleFieldChange(e)}
                {...options}
            />            
        </>
    );
};

export default CustomInputMonetarySEARCH;
