import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
// type={type} field={field} required={required} placeholder={placeholder} label={label}  formik={formik} args={args} 
const CustomInputMonetary = ({ field, label, required, readonly, args, formik }) => {
    const handleFieldChange = (e) => {
        let valor = e.value != null ? e.value.toString() : e.value;
        formik.setFieldValue(field, valor);
    };

    const configs = {
        mode: 'currency',
        currency: 'BRL',
        locale: 'pt-br',
    };
    const options = args && args.currency
        ? {
            mode: args.mode,
            currency: args.currency,
            locale: args.locale,
        }
        : configs;
    const toReal = (value) => {
        if (value)
            return parseFloat(value)

    }

    return (
        <>
            <label htmlFor={field}>{label}</label>
            <InputNumber
                id={field}
                value={formik.values[field]}
                className={`form-control pl-1 ${classNames({ 'p-invalid': formik.touched[field] && formik.errors[field] })}`}
                name={field}
                readOnly={readonly || args?.action === "view" ? true : false}
                onBlur={formik.handleBlur}
                onValueChange={(e) => handleFieldChange(e)}
                {...options}
            />
            {formik.touched[field] && formik.errors[field] && <div className='formik-error'>{formik.errors[field]}</div>}

        </>
    );
};

export default CustomInputMonetary;
