
        import React, { useEffect, useState, useRef, useContext } from 'react'

        import FragmentForm from './fragmentForm';
        import FragmentSearch from './fragmentSearch';
        import FragmentList from './fragmentList';
        import { Aside, Header, Footer } from "../../fragments/index";
        import ButtonShortCut from '../../components/ButtonShortCut.jsx'
        import Permission from '../../utils/Permission.js'
        import Breadcrumb from './breadcrumb.jsx'

        import { AuthContext } from '../../Contexts/Auth/AuthContext'
        import { loadFromLocalStorage } from '../../utils/localStorageUser'
        import ContentProvider from '../../Contexts/Contents/Provider.js';

        function Class() {
            const { setAuth, auth, provider_session, provider_current_client } = useContext(AuthContext)
            
            const SCREEN_ID = '522';

            useEffect(() => {
                console.log('%c useEffect - [ ]: Seta as permissões', 'color: #000; background-color: green;');
                Permission.setScreenId(SCREEN_ID);
                Permission.setCurrentClientId(provider_session.CURRENT_CLIENT_ID);
                Permission.setPermission(loadFromLocalStorage('permission'));
            }, [])

            return (
                <ContentProvider>
                    
        <div id="layout-wrapper">
            <Header />    
            <Aside />                
            <div className="vertical-overlay"></div>
            
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">                
                        <Breadcrumb screen_name="Filiais" />                
                        <FragmentForm/>
                        {/* <FragmentSearch/> */}
                        <FragmentList/>
                    </div>            
                </div>
                <Footer />
            </div>
            <ButtonShortCut page=""></ButtonShortCut>
        </div>
        
                </ContentProvider>
            );
        }

    export default Class;
    