
        export class AbstractDelete {
            constructor(payload) {
                if (new.target === AbstractDelete) {
                    throw new Error('Não é possível instanciar uma classe abstrata diretamente.');
                }      
                this.payload = payload
            }
            
            delete() {
                throw new Error('Método "delete" deve ser implementado pelas subclasses.');
            }    
        }