const SingHelper = {
    /**
     * esta função serve pra seperar uma string e retornar uma parte dela
     * @param  {string} string  String alvo2.
     * @param  {Object} args  Objeto que contem diversas propriedades opcionais.            
     * args.spliter (obrigatoria) = Delimitador utilizado pra fazer o split           
     * args.index (obrigatoria) = indice que deseja retornar como string
     * @return  
     */
    stringSplit(string, args = {}) {        
        return string.split(args.spliter)[args.index];
    },
    /**
     * esta função serve pra converter uma string no padrão de url, assim removendo acentos e espaços em branco
     * @param {string} array - Array de objetos.
     * @param {Object} prop - Propriedade do objeto que se quer retornar no join.
     * @param {integer} separator - Separador utilizado.
     */
    toNumber(string, args = {}) {

        return string.replace(/\d+/g, '');
    },
    /**
     * esta função serve pra converter uma string no padrão de url, assim removendo acentos e espaços em branco
     * @param {string} array - Array de objetos.
     * @param {Object} prop - Propriedade do objeto que se quer retornar no join.
     * @param {integer} separator - Separador utilizado.
     */
    stringClear(string, args = {}) {
        // Remove acentos
        string = string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        let espacoEmBranco = args.whiteSpace ? args.whiteSpace : '_'
        // Remove espaços em branco
        return string.replace(/\s+/g, espacoEmBranco);
    },

    /**
     * esta função serve pra converter uma string no padrão de url, assim removendo acentos e espaços em branco
     * @param {string} array - Array de objetos.
     * @param {Object} prop - Propriedade do objeto que se quer retornar no join.
     * @param {integer} separator - Separador utilizado.
     */
    stringToUrl(string, baseUrl = "") {
        return baseUrl + encodeURIComponent(string)
    },
    objectToUrlParams: (obj) => {
        return new URLSearchParams(obj).toString()
    },
    isEmpty: (obj) => {
        return Object.entries(obj).length == 0;
    },
    toInt: (value, args) => {
        return value.replace(/\D/g, '')
    },
    /* eliminar propriedades vazias de objeto */
    clearObject: (objeto) => {
        for (const chave in objeto) {
            if (objeto[chave] === null || objeto[chave] === undefined || objeto[chave] === '') {
                delete objeto[chave];
            }
        }
        return objeto;
    },
    sanitizeObject: (objReceived, objRef) => {
        const filteredObjB = {};
        Object.keys(objRef).forEach((key, value) => {
            if (objReceived.hasOwnProperty(key)) {
                ////console.log("key[onUpdate]",objRef[key]);
                //if(objRef[key] !== null && "onUpdate" in objRef[key] )
                filteredObjB[key] = objReceived[key];
            }
        });
        return filteredObjB;
    },
    sendDataToForm: (formData, args) => {
        const filteredObjB = {};
        Object.keys(formData).forEach((key, value) => {
            if (formData.hasOwnProperty(key)) {
                ////console.log("key[onUpdate]",objRef[key]);
                //if(objRef[key] !== null && "onUpdate" in objRef[key] )
                filteredObjB[key] = formData[key];
            }
        });
        return filteredObjB;
    },
    converteToDropDown: (data, tabela, coluna, chave) => {
        //NÃO USAR MAIS, USE arrayToDropDown()
        if (data[tabela]) {
            
            return [
                ...data[tabela].map(item => ({ name: item[coluna], code: item[chave] }))
            ];
        }
    },
    arrayToDropDown: (data, tabela, coluna, chave) => {
        if (data) {
            
            return [
                ...data.map(item => ({ name: item[coluna], code: item[chave] }))
            ];
        }
    },
    copyText: async (str, func) => {
        //console.log(str);
        try {
            await navigator.clipboard.writeText(str);
            func();
            //console.log('Texto copiado para a área de transferência');
        } catch (err) {
            //console.log('Falha ao copiar o texto', err);
        }
    },
    Base64: {

        // private property
        _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

        // public method for encoding
        encode: function (input) {
            var output = "";
            var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
            var i = 0;

            input = this._utf8_encode(input);

            while (i < input.length) {

                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);

                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;

                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }

                output = output +
                    this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
                    this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);
            }
            return output;
        },

        // public method for decoding
        decode: function (input) {
            var output = "";
            var chr1, chr2, chr3;
            var enc1, enc2, enc3, enc4;
            var i = 0;

            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

            while (i < input.length) {

                enc1 = this._keyStr.indexOf(input.charAt(i++));
                enc2 = this._keyStr.indexOf(input.charAt(i++));
                enc3 = this._keyStr.indexOf(input.charAt(i++));
                enc4 = this._keyStr.indexOf(input.charAt(i++));

                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;

                output = output + String.fromCharCode(chr1);

                if (enc3 != 64) {
                    output = output + String.fromCharCode(chr2);
                }
                if (enc4 != 64) {
                    output = output + String.fromCharCode(chr3);
                }
            }

            output = this._utf8_decode(output);

            return output;
        },

        // private method for UTF-8 encoding
        _utf8_encode: function (string) {
            string = string.replace(/\r\n/g, "\n");
            var utftext = "";

            for (var n = 0; n < string.length; n++) {

                var c = string.charCodeAt(n);

                if (c < 128) {
                    utftext += String.fromCharCode(c);
                }
                else if ((c > 127) && (c < 2048)) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
                else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
            }
            return utftext;
        },

        // private method for UTF-8 decoding
        _utf8_decode: function (utftext) {
            var string = "";
            var i = 0;
            var c = 0;
            var c1 = 0;
            var c2 = 0;
            var c3 = 0;

            while (i < utftext.length) {

                c = utftext.charCodeAt(i);

                if (c < 128) {
                    string += String.fromCharCode(c);
                    i++;
                }
                else if ((c > 191) && (c < 224)) {
                    c2 = utftext.charCodeAt(i + 1);
                    string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                    i += 2;
                }
                else {
                    c2 = utftext.charCodeAt(i + 1);
                    c3 = utftext.charCodeAt(i + 2);
                    string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                    i += 3;
                }
            }
            return string;
        }
    }
};


export default SingHelper;

