
export default class Model {
    constructor() {
        this.error = [];

        if (new.target === Model) {
            throw new Error('Não é possível instanciar uma classe abstrata diretamente.');
        }

        this.initialValues = {

            "subscriber_id": {
                onCreate: "0",
                type: "int"
            },
            "subscriber_name": {
                onCreate: "1",
                formatter: ["formatterTrim", "formatterUpperCase"],
                type: "varchar"
            },
            "dt_birth": {
                formatter: ["formatterDate"],
                mask: "dmY",
                type: "date"
            },
            "cpf": {
                formatter: ["formatterNumber"],
                mask: "cpf",
                type: "bigint"
            },
            "email": {
                formatter: ["formatterTrim", "formatterLowerCase"],
                type: "varchar"
            },
            "whatsapp_number": {
                onCreate: "1",
                formatter: ["formatterPhone"],
                mask: "cell_phone",
                type: "bigint"
            },
            "_created_user_id": {
                onCreate: "0",
                type: "mediumint"
            },
            "_updated_user_id": {
                onCreate: "0",
                type: "mediumint"
            },
            "_created_at": {
                onCreate: "0",
                mask: "dmY",
                type: "datetime"
            },
            "_updated_at": {
                onCreate: "0",
                mask: "dmY",
                type: "datetime"
            },
            "subscriber_status_id": {
                onCreate: "0",
                type: "mediumint"
            },
            "seller_id": {
                onCreate: "1",
                type: "mediumint"
            },
            "manager_id": {
                onCreate: "1",
                formatter: ["formatterInteger"],
                type: "mediumint"
            },
            "_updated_at_sab": {
                type: "datetime"
            },
            "class_id": {
                type: "mediumint"
            },
            "_responsible_user_id": {
                type: "mediumint"
            },
            "client_id": {
                type: "int"
            },
            "_updated_at_dom": {
                type: "datetime"
            }
        };
    }
    validate() {
        throw new Error('Método "validate" deve ser implementado pelas subclasses.');
    }
    create() {
        throw new Error('Método "create" deve ser implementado pelas subclasses.');
    }
    execute() {
        throw new Error('Método "execute" deve ser implementado pelas subclasses.');
    }
}
