import React, { useState, useEffect } from 'react'

//Função que constroe o Provider e também permite Consumir os Dados Globais
import { ContentContext } from "../../Contexts/Contents/Context";

function ContentProvider({ children }) {
  const [listData, setListData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [getBroadcast, setBroadcast] = useState(null);
  const [action, setAction] = useState("search");

  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    //console.log("~~~~~~~~~ edit Data do Provider atualizado ~~~~~~~~~");
    //console.log(editData);
    if (editData != null)
      setAction("patch")
  }, [editData]);
  
  useEffect(() => {
    console.log('%c~ * ~ USEEFECT - [ listData ] - Provider.js ~ * ~', 'color: white; background-color: #f002c8; padding: 4px;font-size: 12px',listData);    
  }, [listData]);
  
  return (

    <ContentContext.Provider value={{editData, listData, isLoading, action, getBroadcast, setIsLoading, setBroadcast, setEditData, setListData, setAction}}>
      {children}
    </ContentContext.Provider>
  )
}

export default ContentProvider